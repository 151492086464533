import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Switch, Space, Slider, Spin } from "antd";
import ControllerPicture from "../picture/controller";

import { getcontroller } from "../../stored/mydata";

export default function Contact(props) {
  const dataController = useSelector(getcontroller);
  const [dataDevice, setDataDevice] = useState(props.data);
  useEffect(() => {
    if (dataDevice["mac"] === dataController["mac"] && dataDevice["device_id"] === dataController["device_id"]) {
      setDataDevice({...dataDevice, device_status: dataController["status"]});
      // dataDevice["device_status"] = dataController["status"];
    }
  }, [dataController]);

  return (
    <div>
      <div style={styles.card}>
        <Row gutter={[4, 4]}>
          <Col span={2}>
            <div>{ControllerPicture.door()}</div>
          </Col>
          <Col span={14}>
            <div style={styles.textDoor}> { dataDevice["device_name"] } </div>
          </Col>
          <Col span={8}>
            {dataDevice["device_status"] === "off" ? (
              <Row gutter={[4, 4]}>
                <Col>
                  <div style={styles.textStatus}>Closed</div>
                </Col>
                <Col style={{ paddingTop: "4px" }}>
                  {ControllerPicture.lock()}
                </Col>
              </Row>
            ) : (
              <Row gutter={[4, 4]}>
                <Col>
                  <div style={styles.textStatus}>Opened</div>
                </Col>
                <Col style={{ paddingTop: "4px" }}>
                  {ControllerPicture.unlock()}
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}

const styles = {
  card: {
    height: "20px",
    justifyContent: "space-between",
    flexShrink: 0,
    borderRadius: "16px",
    background: "#FFF",
    fontSize: "16px",
    padding: "16px",
    // marginBottom: "16px",
    fontFamily: "Gotham",
    marginBottom: "10px",
  },
  textDoor: {
    paddingTop: "4px",
    color: "var(--Text-Headline-on-light-area, #3EA8B8)",
    // textAlign: "center",
    fontFamily: "Gotham",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px" /* 150% */,
  },
  textStatus: {
    paddingTop: "4px",
    color: "#56585D",
    textAlign: "center",
    fontFamily: "Gotham",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
  },
};
