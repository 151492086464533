import { createSlice } from '@reduxjs/toolkit'

export const counterSlice = createSlice({
    name: 'mydata',
    initialState: {
      userlogin: false,
      user: {},
      project: {},
      token: '',
      iaq: {},
      emu: {},
      roomId: '',
      socketStatus: false,
      devices: {},
      controller: {},
      roomScene: [],
      sceneName: {},
      isreload: false,
      sceneAll: [],
      showCtl: false,
    },
    reducers: {
      setuserloginStored: (state, action) => {
        let tmp = action.payload;
        state.userlogin  = tmp;
      },
      setuserStored: (state, action) => {
        let tmp = action.payload;
        state.user  = tmp;
      },
      setprojectStored: (state, action) => {
        let tmp = action.payload;
        state.project  = tmp;
      },
      settokenStored: (state, action) => {
        let tmp = action.payload;
        state.token  = tmp;
      },
      setiaqStored: (state, action) => {
        let tmp = action.payload;
        state.iaq = tmp;
      },
      setemuStored: (state, action) => {
        let tmp = action.payload;
        state.emu = tmp;
      },
      setroomIdStored: (state, action) => {
        let tmp = action.payload;
        state.roomId = tmp;
      },
      setsocketStatusStored: (state, action) => {
        let tmp = action.payload;
        state.socketStatus = tmp;
      },
      setdevicesStored: (state, action) => {
        let tmp = action.payload;
        state.devices = tmp;
      },
      setcontrollerStored: (state, action) => {
        let tmp = action.payload;
        state.controller = tmp;
      },
      setroomSceneStored: (state, action) => {
        let tmp = action.payload;
        state.roomScene = tmp;
      },
      setsceneNameStored: (state, action) => {
        let tmp = action.payload;
        state.sceneName = tmp;
      },
      setisreloadStored: (state, action) => {
        let tmp = action.payload;
        state.isreload = tmp;
      },
      setsceneAllStored: (state, action) => {
        let tmp = action.payload;
        state.sceneAll = tmp;
      },
      setshowCtlStored: (state, action) => {
        let tmp = action.payload;
        state.showCtl = tmp;
      },
    }
  })
  
  // Action creators are generated for each case reducer function
  export const { setuserloginStored, setuserStored, setprojectStored, settokenStored, setiaqStored, setemuStored, setroomIdStored, setsocketStatusStored, setdevicesStored, setcontrollerStored, setroomSceneStored, setsceneNameStored, setisreloadStored, setsceneAllStored, setshowCtlStored } = counterSlice.actions

  export const getUserlogin = state => state.counter.userlogin
  export const getUser = state => state.counter.user
  export const getProject = state => state.counter.project
  export const getToken = state => state.counter.token
  export const getiaq = state => state.counter.iaq
  export const getemu = state => state.counter.emu
  export const getroomid = state => state.counter.roomId
  export const getsocketstatus = state => state.counter.socketStatus
  export const getdevices = state => state.counter.devices
  export const getcontroller = state => state.counter.controller
  export const getroomScene = state => state.counter.roomScene
  export const getsceneName = state => state.counter.sceneName
  export const getisreload = state => state.counter.isreload
  export const getsceneAll = state => state.counter.sceneAll
  export const getshowCtl = state => state.counter.showCtl
  
  export default counterSlice.reducer