const DeleteScene = (id, cb) => {
  // console.log("api Delete");
    fetch(
        `${process.env.REACT_APP_API_OBT}/api/v1/scene?scene_id=${id}`,
        {
          method: "DELETE",
          headers: {
            "client-id": "obt-oneapp",
          },
        }
      )
        .then((res) => res.json())
        .then((json) => cb(null, json))
        .catch((err) => cb(err, null));
};

export default DeleteScene;