const GetEmu = (data, cb) => {
    // console.log("GetIaq");

    fetch(`${process.env.REACT_APP_API_OBT}/api/v1/emu?EMU_ID=${data['EMU_ID']}&SelectedBy=${data['SelectedBy']}&SelectedTime=${data['SelectedTime']}&DateTime=${data['DateTime']}`, {
        method: 'GET',
        headers: {
            'client-id': 'obt-oneapp',
        },
    })
    .then(res => res.json())
    .then(json => cb(null, json))
    .catch(err => cb(err, null))
};
export default GetEmu;