import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Modal, Button, Switch, Space, Radio, Slider } from "antd";
import ControllerPicture from "../../components/picture/controller";

export default function Erv(props) {
  const [data, setData] = useState({
    id: 1,
    house_number: "181/1",
    room_name: "Living Room",
    mac: "0038004442435708",
    device_id: "erv",
    device_name: "ERV Living",
    device_model: "vent",
    device_type: "erv",
    device_status: "off",
    device_mode: "heat",
    device_fanspeed: "high",
    device_temp: null,
    device_sweep: null,
    device_group: null,
    device_gang: null,
  });

  const [valueMode, setValueMode] = useState("");

  useEffect(() => {
    if (props.data) {
      setData(props.data);
      setValueMode(props.data["device_mode"]);
    }
    return () => {
      // setData({})
    };
  }, [props.data]);

  useEffect(() => {
    props.getData(data);
    return () => {};
  }, [data]);

  const modes = [
    {
      key: 1,
      value: "supply",
      name: "Supply",
    },
    {
      key: 2,
      value: "vent",
      name: "Vent",
    },
    {
      key: 3,
      value: "heat",
      name: "Heat",
    },
  ];

  const marks = {
    0: (
      <>
        <div style={styles.textModeFan}>Low</div>
      </>
    ),
    50: (
      <>
        <div style={styles.textModeFan}>Mid</div>
      </>
    ),
    100: (
      <>
        <div style={styles.textModeFan}>High</div>
      </>
    ),
  };

  const fanspeed = (value) => {
    if (value === "low") {
      return 0;
    } else if (value === "medium") {
      return 50;
    } else if (value === "high") {
      return 100;
    } else {
      return 0;
    }
  };

  const controllerSpeed = (value) => {
    let speed = "low";
    if (value === 0) {
      speed = "low";
    } else if (value === 50) {
      speed = "medium";
    } else if (value === 100) {
      speed = "high";
    } else {
      speed = "low";
    }
    setData({ ...data, device_fanspeed: speed });
  };

  const controllerMode = (value) => {
    var data_value = value.target.value;
    setData({ ...data, device_mode: data_value.toLowerCase() });
    setValueMode(data_value);
  };

  return (
    <div>
      {data.select_scene === "true" ? (
        <div style={styles.cardChoose}>
          <Row>
            <Col span={20}>
              <Row>
                <Col span={3} style={{ width: "200px" }}>
                  <div
                    onClick={() => {
                      setData({ ...data, select_scene: "false", device_status: "off" });
                    }}
                  >
                    {ControllerPicture.erv()}
                  </div>
                </Col>
                <Col span={21}>
                  <div
                    style={styles.fontInpage}
                    onClick={() => {
                      setData({ ...data, select_scene: "false", device_status: "off"  });
                    }}
                  >
                    Energy Recovery Ventilation
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={4} style={{ textAlign: "end", paddingTop: "3px" }}>
              <Space direction="vertical">
                <Switch
                  onChange={(e) => {
                    let status = "off";
                    if (e) {
                      status = "on";
                    }
                    setData({ ...data, device_status: status });
                  }}
                  checked={data["device_status"] === "on" ? true : false}
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                />
              </Space>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={4} style={styles.textMode}>
              Mode:
            </Col>
            <Col span={20}>
              <Radio.Group onChange={controllerMode} value={valueMode}>
                <Row>
                  {modes.map((e) => {
                    return (
                      <Col span={8}>
                        <div>
                          <Radio value={e.value} disabled={data["device_status"] === "off" ? true : false}>
                            <div
                              style={
                                valueMode === e.value
                                  ? styles.fontNumber_choose
                                  : styles.fontNumber
                              }
                            >
                              {e.name === "Heat" ? (
                                <>{ControllerPicture.heat()}</>
                              ) : null}
                              {e.name === "Vent" ? (
                                <>{ControllerPicture.vent()}</>
                              ) : null}
                              {e.name === "Supply" ? (
                                <>{ControllerPicture.supply()}</>
                              ) : null}
                            </div>
                          </Radio>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Radio.Group>
            </Col>
          </Row>
          <div style={styles.cardInerv}>
            <Row gutter={[8, 8]}>
              <Col span={7}>
                <div>
                  <Row>
                    <Col span={12} style={{ width: "250px" }}>
                      {ControllerPicture.wind()}
                    </Col>
                    <Col span={12}>
                      <div style={styles.fontInpage}>Fan</div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col span={17}>
                <div>
                  <Slider
                    disabled={data["device_status"] === "off" ? true : false}
                    marks={marks}
                    value={fanspeed(data["device_fanspeed"])}
                    step={50}
                    onChange={controllerSpeed}
                    tooltip={{
                      formatter: null,
                    }}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      ) : null}
      {data.select_scene === "false" ? (
        <div style={styles.card}>
          <Row>
            <Col span={20}>
              <Row>
                <Col span={3} style={{ width: "200px" }}>
                  <div
                    onClick={() => {
                      setData({ ...data, select_scene: "true", device_status: "on" });
                    }}
                  >
                    {ControllerPicture.ervOff()}
                  </div>
                </Col>
                <Col span={21}>
                  <div
                    style={styles.ontInpageOff}
                    onClick={() => {
                      setData({ ...data, select_scene: "true", device_status: "on" });
                    }}
                  >
                    Energy Recovery Ventilation
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={4} style={{ textAlign: "end", paddingTop: "3px" }}>
              <Space direction="vertical">
                <Switch
                  disabled
                  checked={false}
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                />
              </Space>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={4} style={styles.textMode}>
              Mode:
            </Col>
            <Col span={20}>
              <Radio.Group value={valueMode} disabled>
                <Row>
                  {modes.map((e) => {
                    return (
                      <Col span={8}>
                        <div>
                          <Radio value={e.value} >
                            <div>
                              {e.name === "Heat" ? (
                                <>{ControllerPicture.heat()}</>
                              ) : null}
                              {e.name === "Vent" ? (
                                <>{ControllerPicture.vent()}</>
                              ) : null}
                              {e.name === "Supply" ? (
                                <>{ControllerPicture.supply()}</>
                              ) : null}
                            </div>
                          </Radio>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Radio.Group>
            </Col>
          </Row>
          <div style={styles.cardInervoff}>
            <Row gutter={[8, 8]}>
              <Col span={7}>
                <div>
                  <Row>
                    <Col span={12} style={{ width: "250px" }}>
                      {ControllerPicture.wind()}
                    </Col>
                    <Col span={12}>
                      <div style={styles.fontInpageOff}>Fan</div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col span={17}>
                <div>
                  <Slider
                    disabled
                    value={fanspeed(data["device_fanspeed"])}
                    marks={marks}
                    step={50}
                    tooltip={{
                      formatter: null,
                    }}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      ) : null}
    </div>
  );
}

const styles = {
  head: {
    backgroundColor: "#F0F9FB",
    height: "108px",
  },
  card: {
    display: "flex",
    padding: "16px",
    flexDirection: "column",
    gap: "12px",
    borderRadius: "16px",
    border: "1px solid #E3E3E3",
  },
  cardChoose: {
    display: "flex",
    padding: "16px",
    flexDirection: "column",
    gap: "12px",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
    background: "#EBF4FA",
  },
  cardInerv: {
    flexShrink: "0",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
    background: "#FFF",
    padding: "15px 15px 0 15px",
  },
  fontInpage: {
    color: "#3EA8B8",
    // textAlign: "center",
    paddingTop: "5px",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px" /* 150% */,
  },
  fontNumber_choose: {
    color: "#56585D",
    textAlign: "center",
    fontFamily: "Gotham",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
  },
  fontNumber: {
    color: "#919499",
    textAlign: "center",
    fontFamily: "Gotham",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
  },
  textMode: {
    color: "#56585D",
    // textAlign: "center",
    fontFamily: "Gotham",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "21px",
  },
  textModeFan: {
    color: "#56585D",
    // textAlign: "center",
    marginTop: "10px",
    fontFamily: "Gotham",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "21px",
  },

  ontInpageOff: {
    color: "#56585D",
    paddingTop: "5px",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
  },
  fontInpageOff: {
    color: "#56585D",
    paddingTop: "5px",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px" /* 150% */,
  },
  cardInervoff: {
    flexShrink: "0",
    borderRadius: "16px",
    border: "1px solid #E3E3E3",
    background: "#FFF",
    padding: "15px 15px 0 15px",
  },

};
