const PostFavorite = (data, cb) => {

    fetch(`${process.env.REACT_APP_API_OBT}/api/v1/favorite?room_id=${data['room_id']}&status=${data['status']}`,
        {
            method: "POST",
            headers: {
                "client-id": "obt-oneapp",
            },
        }
    )
    .then((res) => res.json())
    .then((json) => cb(null, json))
    .catch((err) => cb(err, null));
};
export default PostFavorite;