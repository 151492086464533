import React, { useEffect, useState } from "react";
import { Row, Col, Image } from "antd";
import { RightOutlined } from "@ant-design/icons";
export default function Devicebox(props) {
  const [data, setData] = useState({
    id: 2,
    name: "Kitchen",
    icon: "icon-",
  });
  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
    return () => {
      // cleanup
    };
  }, [props]);
  return (
    <div
      onClick={() => {
        props.handleSet(data);
      }}
      style={{ margin: "15px 0px 15px 0px"}}
    >
      <Row style={{ margin: "4px 4px" }}>
        <Col span={3}>
          <Image preview={false} width={30} src={data.path} />
        </Col>
        <Col span={20}>
          <span style={{ fontWeight: 500 }}>{data.room_name}</span>
        </Col>
        <Col span={1}>
          <RightOutlined />
        </Col>
      </Row>
      {/* <hr style={styles.underlineSchedule}></hr> */}
    </div>
  );
}

const styles = {
  underlineSchedule: {
    backgroundColor: "#919499",
    opacity: "0.2",
    marginTop: "10px",
  },
};
