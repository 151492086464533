import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ScenePicture from "../../components/picture/sceneicon";
import {
  getUser,
  setroomSceneStored,
  setsceneNameStored,
} from "../../stored/mydata";
import {
  Row,
  Col,
  Input,
  Image,
  Spin,
  Layout,
  FloatButton,
  ConfigProvider,
} from "antd";

import apigetIcons from "../../api/scene/getIcons";
import apipostCreateScene from "../../api/scene/postCreateScene";
import apigetRoom from "../../api/home/getRoom";

import setformatData from "../../api/setFormat_Data";

const { Header, Content, Footer } = Layout;

export default function SceneAdd() {
  var navigate = useNavigate();
  const dispatch = useDispatch();

  const windowWidth = useRef(window.innerWidth);
  const [btnwidth, setBtnwidth] = useState(0); //380

  const myUser = useSelector(getUser);

  const [isshow, setIsshow] = useState(false);
  const [icons, setIcon] = useState([]);
  const [sceneName, setSceneName] = useState({
    name: "",
    icon_id: "6",
  });

  useEffect(() => {
    getIcons();
    getRoom();
    settingWidth();
  }, []);

  const getIcons = () => {
    var data = {
      scenes: "scenes",
    };

    apigetIcons(data, (err, res) => {
      if (res.message === "Success") {
        setIcon(res.icons);
        // setStep(1);
      }
    });
  };

  const getRoom = () => {
    var data = {
      house_number: myUser.house_number,
      project_id: myUser.project_code,
      unit_number: myUser.unit_number,
    };

    apigetRoom(data, (err, res) => {
      if (res.message === "Success") {
        var rooms = setformatData(res.rooms, "scene");

        // for (let i = 0; i < rooms.length; i++) {
        //   rooms[i]["device"].map((e) => {
        //     e["select_scene"] = "false";

        //     if (e["device_type"] === "lights") {
        //       e["device_status"] = "off";
        //     } else if (e["device_type"] === "ac") {
        //       e["device_status"] = "off";
        //       e["device_temp"] = "15";
        //       e["device_mode"] = "cool";
        //       e["device_fanspeed"] = "high";
        //     } else if (e["device_type"] === "curtains") {
        //       e["device_status"] = "on";
        //     }
        //   });
        // }
        dispatch(setroomSceneStored(rooms));
      }
    });
  };

  const settingWidth = () => {
    var btn_width = 0;

    if (windowWidth.current <= 400) {
      btn_width = windowWidth.current - 350;
    } else if (windowWidth.current <= 430) {
      btn_width = windowWidth.current - 370;
    } else if (windowWidth.current <= 768) {
      btn_width = windowWidth.current - 530;
    } else if (windowWidth.current <= 1024) {
      btn_width = windowWidth.current - 650;
    }
    setBtnwidth(btn_width);
    setIsshow(true);
  };

  const createScene = () => {
    var raw = JSON.stringify({
      scene_name: sceneName.name,
      icon_id: sceneName.icon_id,
      house_number: myUser.house_number,
      project_id: myUser.project_code,
      unit_number: myUser.unit_number,
    });

    apipostCreateScene(raw, (err, res) => {
      if (res.message === "Success") {
        dispatch(
          setsceneNameStored({
            scene_id: res.scene_id,
            scene_name: res.scene_name,
          })
        );
        navigate("/scenerooms/" + res.scene_id);
      }
    });
  };

  return (
    <Layout>
      <Content>
        <div>
          {isshow === false ? (
            <div style={styles.reload}>
              <Spin size="large" />
            </div>
          ) : (
            <div className="parentDiv">
              <div className="childDiv" style={{ backgroundColor: "#FBFBFB" }}>
                <div name="head" style={styles.head}>
                  <Row style={{ padding: "13px 16px" }}>
                    <Col span={4}>
                      <div
                        onClick={() => {
                          navigate("/scene");
                        }}
                      >
                        {ScenePicture.backpage()}
                      </div>
                    </Col>
                    <Col span={16} style={styles.textHead}>
                      <div>Add scene</div>
                    </Col>
                    <Col span={4}></Col>
                  </Row>
                </div>
                <div style={styles.bodyPage}>
                  <div style={styles.textScene}>Scene</div>
                  <div style={{ marginTop: "16px" }}>
                    <Row>
                      <Col span={7}>
                        <div style={styles.boxPicture}>
                          <div style={{ margin: "16px" }}>
                            <div style={{ textAlign: "center" }}>
                              {icons.map((e) => {
                                if (parseInt(sceneName.icon_id) === e.id) {
                                  return (
                                    <Image
                                      preview={false}
                                      width={40}
                                      src={e.name}
                                    />
                                  );
                                }
                                return <></>;
                              })}
                            </div>
                            <div
                              style={{
                                textAlign: "center",
                                whiteSpace: "nowrap",
                                // width: "50px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {sceneName["name"] !== ""
                                ? sceneName["name"]
                                : "Name"}
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col span={17}>
                        <div style={{ borderBottom: "1px solid #C0C0C0" }}>
                          <Input
                            placeholder="Name"
                            bordered={false}
                            value={sceneName.name}
                            onChange={(e) => {
                              setSceneName({
                                ...sceneName,
                                name: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div
                          style={{
                            overflow: "auto",
                            display: "flex",
                            marginTop: "10px",
                          }}
                        >
                          {icons.map((e, i) => {
                            var colorBg = styles.borderIcon;
                            if (e.id === parseInt(sceneName.icon_id)) {
                              colorBg = styles.borderIconAction;
                            }
                            return (
                              <div style={styles.boxIcon} key={i + "_icon"}>
                                <div
                                  style={colorBg}
                                  onClick={() => {
                                    setSceneName({
                                      ...sceneName,
                                      icon_id: e["id"].toString(),
                                    });
                                  }}
                                >
                                  <Image
                                    preview={false}
                                    width={40}
                                    src={e.name}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div style={{ marginTop: "34px" }}>
                    <div style={styles.textRead}>
                      <span style={{ paddingLeft: "4px" }}>
                        Creating a scene allows for simultaneous control of
                        multiple devices, instantly triggering them to turn on
                        or off based on preset settings
                      </span>
                    </div>
                  </div>
                  <div style={{ marginTop: "8px" }}>
                    <div style={styles.textReadTwo}>
                      <span style={{ paddingLeft: "10px" }}>
                        Example Scene Creations:
                      </span>{" "}
                      <br />
                      <span style={{ paddingLeft: "10px" }}>
                        “Turn off Bedroom Lights"
                      </span>
                      <ul>
                        <li>Controlled Devices: Bedroom lights</li>
                        <li>
                          Action: Immediately turns off the bedroom lights when
                          triggered.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorBgElevated:
                          sceneName["name"] !== "" ? "#3EA8B8" : "#919499",
                        colorText: "rgba(251, 251, 251, 1)",
                      },
                    }}
                  >
                    <FloatButton
                      style={{
                        left: `${btnwidth}px`,
                        // right: `${btnwidth}px`,
                        display: "flex",
                        width: "312px",
                        height: "48px",
                        flexShrink: "0",
                        borderRadius: "8px",
                      }}
                      onClick={() => {
                        if (sceneName["name"] !== "") {
                          createScene();
                        }
                      }}
                      description={
                        <div style={styles.textInbtn}>create a scene</div>
                      }
                    />
                  </ConfigProvider>
                </div>
              </div>
            </div>
          )}
        </div>
      </Content>
    </Layout>
  );
}

const styles = {
  head: {
    backgroundColor: "#F0F9FB",
    height: "56px",
  },
  textHead: {
    color: "#3EA8B8",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    fontFamily: "Gotham",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "350",
    lineHeight: "30px",
    letterSpacing: "0.15px",
  },
  bodyPage: {
    // backgroundColor: "#FBFBFB",
    padding: "20px 15px 20px 15px",
    // width: "360px",
    // height: "800px",
  },
  textScene: {
    color: "rgba(86, 88, 93, 1)",
    fontFeatureSettings: "clig off, liga off",
    fontFamily: "Gotham",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "350",
    lineHeight: "24px" /* 150% */,
    letterSpacing: "0.4px",
  },
  itemInRow: {
    display: "flex",
    alignItems: "center",
  },
  itemInRowStart: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "10px",
  },
  floating_button_div: {
    position: "fixed",
    bottom: "20px",
    // float: 'left',
    // width: '33%',
    // alignItems: "center",
    right: "60px",
  },
  btnCreate: {
    display: "flex",
    width: "312px",
    height: "48px",
    padding: "9px 0px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
    borderRadius: "8px",
    border: "1px solid #919499",
    backgroundColor: "#919499",
    color: "rgba(251, 251, 251, 1)",
    fontSize: "14px",
    fontFamily: "Gotham",
    fontWeight: "350",
    lineHeight: "21px",
  },
  textInbtn: {
    color: "#FBFBFB",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    // fontFamily: "Gotham",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "21px",
    letterSpacing: "1px",
    textTransform: "uppercase",
  },
  btnCreateActton: {
    display: "flex",
    width: "312px",
    height: "48px",
    padding: "9px 0px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
    borderRadius: "8px",
    border: "1px solid #3EA8B8",
    backgroundColor: "#3EA8B8",
    color: "#FBFBFB",
    fontSize: "14px",
    fontFamily: "Gotham",
    fontWeight: "350",
    lineHeight: "21px",
  },
  textInbtnA: {
    color: "#FBFBFB",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    // fontFamily: "Gotham",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "21px",
    letterSpacing: "1px",
    textTransform: "uppercase",
  },
  boxPicture: {
    width: "96px",
    height: "96px",
    flexShrink: "0",
    borderRadius: "16px",
    border: "1px solid var(--primary-meadows-100, #B5EAEF)",
    background: "var(--text-on-dark-area",
  },
  boxIcon: {
    width: "52px",
    height: "52px",
    margin: "0 5px",
  },
  borderIcon: {
    width: "42px",
    height: "42px",
    border: "1px solid var(--primary-meadows-100, #B5EAEF)",
    borderRadius: "50%",
    display: "inline-block",
    backgroundColor: "#FFF",
  },
  borderIconAction: {
    width: "42px",
    height: "42px",
    border: "1px solid var(--primary-meadows-100, #B5EAEF)",
    borderRadius: "50%",
    display: "inline-block",
    backgroundColor: "#EBF4FA",
  },
  textRead: {
    color: "#56585D",
    textAlign: "center",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Gotham",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px" /* 166.667% */,
  },
  textReadTwo: {
    color: "#56585D",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Gotham",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px" /* 166.667% */,
  },
  reload: {
    margin: "20px 0",
    marginBottom: "20px",
    padding: "30px 50px",
    textAlign: "center",
    borderRadius: "4px",
    display: "inherit",
  },
};
