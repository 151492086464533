import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Switch, Space, Slider } from "antd";
import ControllerPicture from "../../components/picture/controller";
import apipostFreshair from "../../api/controller/postFreshair";

import { getcontroller, setshowCtlStored } from "../../stored/mydata";

export default function Freshair(props) {
  const dispatch = useDispatch();

  const dataController = useSelector(getcontroller);
  const [isShow, setIsshow] = useState(false);
  const [dataDevice, setDataDevice] = useState({
    device_id: "fa-br-0",
    device_type: "freshairNew",
    device_status: "off",
    device_mode: "low",
    device_all: [],
  });

  useEffect(() => {
    // var count_on = 0;

    var data_all = props.data;
    setDataDevice(data_all);

    setIsshow(true);
  }, []);

  useEffect(() => {
    if (dataController["status"] === "on") {
      dataDevice["device_all"].map((e) => {
        if (
          e["mac"] === dataController["mac"] &&
          e["device_id"] === dataController["device_id"] &&
          e["device_gang"] === "1"
        ) {
          setDataDevice({
            ...dataDevice,
            device_mode: "low",
            device_status: "on",
          });
        } else if (
          e["mac"] === dataController["mac"] &&
          e["device_id"] === dataController["device_id"] &&
          e["device_gang"] === "2"
        ) {
          if (dataDevice["device_status"] === "on") {
            setDataDevice({
              ...dataDevice,
              device_mode: "high",
            });
          }
        }
      });
    } else if (dataController["status"] === "off") {
      dataDevice["device_all"].map((e) => {
        if (
          e["mac"] === dataController["mac"] &&
          e["device_id"] === dataController["device_id"] &&
          e["device_gang"] === "1"
        ) {
          setDataDevice({
            ...dataDevice,
            device_mode: "low",
            device_status: "off",
          });
        } else if (
          e["mac"] === dataController["mac"] &&
          e["device_id"] === dataController["device_id"] &&
          e["device_gang"] === "2"
        ) {
          if (dataDevice["device_status"] === "on") {
            setDataDevice({
              ...dataDevice,
              device_mode: "low",
            });
          }
        }
      });
    }
  }, [dataController]);

  const ervspeed = (value) => {
    if (value === "low") {
      return 0;
    } else if (value === "high") {
      return 100;
    } else {
      return 0;
    }
  };

  const marks = {
    0: (
      <>
        <div style={styles.textModeFan}>Low</div>
      </>
    ),
    100: (
      <>
        <div style={styles.textModeFan}>High</div>
      </>
    ),
  };

  const apiControll = (value) => {
    dispatch(setshowCtlStored(true));

    const data_api = JSON.stringify(value);

    apipostFreshair(data_api, (err, res) => {
      if (res["message"] === "Success") {
        // console.log("controllerSwit");

        var count_time = 3;
        let timerId = setInterval(() => {
          count_time--;

          if (count_time === 0) {
            dispatch(setshowCtlStored(false));
            clearInterval(timerId);
          }
        }, 1000);
      }
    });
  };

  const controllerSwitch = (e) => {
    if (e) {
      dataDevice["device_all"].map((e) => {
        if (e.device_gang === "1") {
          var data = {
            mac: e["mac"],
            device_id: e["device_id"],
            device_model: e["device_model"],
            device_type: e["device_type"],
            device_group: e["device_group"],
            device_gang: e["device_gang"],
            status: "on",
          };
          apiControll(data);
        }
      });
    } else {
      dataDevice["device_all"].map((e) => {
        // if (e.device_gang === "1") {
        var data = {
          mac: e["mac"],
          device_id: e["device_id"],
          device_model: e["device_model"],
          device_type: e["device_type"],
          device_group: e["device_group"],
          device_gang: e["device_gang"],
          status: "off",
        };
        apiControll(data);
        // }
      });
    }
  };

  const controllerSpeed = (value) => {
    if (value === 100) {
      if (dataDevice["device_status"] === "on") {
        dataDevice["device_all"].map((e) => {
          if (e["device_gang"] === "2") {
            var data = {
              mac: e["mac"],
              device_id: e["device_id"],
              device_model: e["device_model"],
              device_type: e["device_type"],
              device_group: e["device_group"],
              device_gang: e["device_gang"],
              status: "on",
            };
            apiControll(data);
          }
        });
      }
    } else if (value === 0) {
      dataDevice["device_all"].map((e) => {
        if (e["device_gang"] === "2") {
          var data = {
            mac: e["mac"],
            device_id: e["device_id"],
            device_model: e["device_model"],
            device_type: e["device_type"],
            device_group: e["device_group"],
            device_gang: e["device_gang"],
            status: "off",
          };
          apiControll(data);
        }
      });
    }
  };

  return (
    <div>
      {isShow ? (
        <div
          style={
            dataDevice["device_status"] === "on"
              ? styles.card
              : styles.cardAction
          }
        >
          <Row>
            <Col span={20}>
              <Row
                onClick={() => {
                  if (dataDevice["device_status"] === "on") {
                    controllerSwitch(false);
                  } else {
                    controllerSwitch(true);
                  }
                }}
              >
                <Col span={3} style={{ width: "200px" }}>
                  {dataDevice["device_status"] === "on"
                    ? ControllerPicture.freshair()
                    : ControllerPicture.freshairOff()}
                </Col>
                <Col span={21}>
                  <div
                    style={
                      dataDevice["device_status"] === "on"
                        ? styles.fontHead
                        : styles.fontHeadAction
                    }
                  >
                    Fresh Air
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={4} style={{ textAlign: "end", paddingTop: "3px" }}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                  onChange={controllerSwitch}
                  checked={dataDevice["device_status"] === "on" ? true : false}
                />
              </Space>
            </Col>
          </Row>
          <div
            style={
              dataDevice["device_status"] === "on"
                ? styles.cardInerv
                : styles.cardInervAction
            }
          >
            <Row gutter={[8, 8]}>
              <Col span={7}>
                <div>
                  <Row>
                    <Col span={12} style={{ width: "250px" }}>
                      {ControllerPicture.wind()}
                    </Col>
                    <Col span={12}>
                      <div
                        style={
                          dataDevice["device_status"] === "on"
                            ? styles.fontInpage
                            : styles.fontInpageAction
                        }
                      >
                        Fan
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col span={17}>
                {dataDevice["device_status"] === "on" ? (
                  <div>
                    <Slider
                      marks={marks}
                      defaultValue={0}
                      step={100}
                      value={ervspeed(dataDevice["device_mode"])}
                      onChange={controllerSpeed}
                      tooltip={{
                        formatter: null,
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <Slider
                      disabled
                      marks={marks}
                      defaultValue={0}
                      step={100}
                      value={ervspeed(dataDevice["device_mode"])}
                      tooltip={{
                        formatter: null,
                      }}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </div>
      ) : null}
    </div>
  );
}

const styles = {
  card: {
    display: "flex",
    padding: "16px",
    flexDirection: "column",
    // alignItems: "flex-start",
    gap: "12px",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
  },
  cardAction: {
    display: "flex",
    padding: "16px",
    flexDirection: "column",
    // alignItems: "flex-start",
    gap: "12px",
    borderRadius: "16px",
    border: "1px solid #E3E3E3",
  },
  cardInerv: {
    flexShrink: "0",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
    background: "rgba(251, 251, 251, 0.1)",
    padding: "15px 15px 0 15px",
  },
  cardInervAction: {
    flexShrink: "0",
    borderRadius: "16px",
    border: "1px solid #E3E3E3",
    background: "rgba(251, 251, 251, 0.1)",
    padding: "15px 15px 0 15px",
  },
  fontHead: {
    color: "#3EA8B8",
    fontFamily: "Gotham",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
  },
  fontHeadAction: {
    color: "#E3E3E3",
    fontFamily: "Gotham",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
  },
  fontInpage: {
    color: "#3EA8B8",
    // textAlign: "center",
    paddingTop: "5px",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px" /* 150% */,
  },
  fontInpageAction: {
    color: "#E3E3E3",
    // textAlign: "center",
    paddingTop: "5px",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px" /* 150% */,
  },
  textMode: {
    color: "#56585D",
    // textAlign: "center",
    fontFamily: "Gotham",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "21px",
  },
  textModeFan: {
    color: "#56585D",
    // textAlign: "center",
    marginTop: "10px",
    fontFamily: "Gotham",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "21px",
  },
};
