import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import RoomTab from "../../components/home/tabroom";
// import EnergyTab from "../../components/home/tabenergy";
// import apiOne from "../../api/overview/getOne";
// import HomePicture from "../../components/picture/homeicon";
import ScenePicture from "../../components/picture/sceneicon";
import CardIaq from "../../components/historyIaq/cardIaq";
import { getiaq } from "../../stored/mydata";
import getIaq from "../../api/home/getIaq";
import SetFormatIaq from "../../api/setFormat_Iaq";

import { Row, Col, Modal, Button, Spin } from "antd";

export default function History(props) {
  var navigate = useNavigate();

  const windowWidth = useRef(window.innerWidth);
  const windowHeight = useRef(window.innerHeight);

  const iaqs = useSelector(getiaq);

  const [iaq, setIaq] = useState([]);
  const [dataIaq, setDataIaq] = useState({});
  const [modaliaq, setModaliaq] = useState(false);
  const [isshow, setIsshow] = useState(false);

  useEffect(() => {
    var data = {
      SelectedTime: "minuteinday",
      IAQ_ID: iaqs["mac"],
    };
    getIaq(data, (err, res) => {
      if (res["message"] === "Success") {
        setIaq(SetFormatIaq(res));
        // console.log(SetFormatIaq(res));
        setIsshow(true);
      }
    });
  }, []);

  // const stopModaliaq = () => {
  //   setModaliaq(false);
  // };
  // const setDataiaq = (data) => {
  //   setDataIaq(data);
  // };
  return (
    <div>
      {isshow === false ? (
        <div style={styles.reload}>
          <Spin size="large" />{" "}
        </div>
      ) : (
        <div className="parentDiv">
          <div className="childDiv">
            <div name="head" style={styles.head}>
              <Row style={{ padding: "13px 16px" }}>
                <Col span={4}>
                  <div
                    onClick={() => {
                      navigate("/home");
                    }}
                  >
                    {ScenePicture.backpage()}
                  </div>
                </Col>
                <Col
                  span={16}
                  style={{
                    textAlign: "center",
                    fontFamily: "Gotham",
                    fontSize: "20px",
                    color: "#3EA8B8",
                    fontWeight: "500",
                  }}
                >
                  <div>History</div>
                </Col>
                <Col span={4}></Col>
              </Row>
            </div>
            <div
              style={{
                padding: "16px",
                background: "rgba(251, 251, 251, 1)",
                // marginRight: "20px",
              }}
            >
              <div>
                {iaq.map((e, i) => {
                  return <CardIaq data={e} page={"history"} />;
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const styles = {
  headIn: {
    padding: "10% 1% 4% 7%",
    color: "#373A3F",
    // paddingLeft: "7%",
    fontFamily: "Gotham",
    fontSize: "18px",
  },
  head: {
    backgroundColor: "#F0F9FB",
    height: "56px",
  },
  card: {
    fontFamily: "Gotham",
    borderRadius: "16px",
    background: "#FFF",
    fontSize: "18px",
  },
  textNameInCard: {
    fontFamily: "Gotham",
    fontSize: "18px",
    color: "rgba(86, 88, 93, 1)",
  },
  textValueInCard: {
    fontFamily: "Gotham",
    fontSize: "20px",
    paddingLeft: "2%",
    color: "rgba(86, 88, 93, 1)",
  },
  textUnitInCard: {
    fontSize: "16px",
    marginTop: "-15%",
    color: "rgba(145, 148, 153, 1)",
  },
  reload: {
    margin: "20px 0",
    marginBottom: "20px",
    padding: "30px 50px",
    textAlign: "center",
    borderRadius: "4px",
    display: "inherit",
  },
};
