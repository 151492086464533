import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ControllerPicture from "../../components/picture/controller";
import { Row, Col, Spin } from "antd";

import Ervdevice from "../../components/controller/erv";
import Doasdevice from "../../components/controller/doas";
import Airdevice from "../../components/controller/air";
import Lampdevice from "../../components/controller/lamp";
import Chandelierdevice from "../../components/controller/chandelier";
import Curtaindevice from "../../components/controller/curtain";
import ScenePicture from "../../components/picture/sceneicon";
import MotionSenserdevice from "../../components/controller/motionsensor";
import Freshairdevice from "../../components/controller/freshair";
import AirCassettedevice from "../../components/controller/airCassette";
import AirCassette2device from "../../components/controller/airCassette2";
import AirCoolthingdevice from "../../components/controller/airCoolthing";
import DoorContactSensordevice from "../../components/controller/doorcontactsensor";
// import Curtain from "../../components/controller/curtain";
import AirAllInOne from "../../components/controller/airAllInOne";
import AirCondition from "../../components/controller/airCondition";
import LightingDim from "../../components/controller/lightingDim";
import {
  // setshowCtlStored,
  // setcontrollerStored,
  getroomid,
  getUser,
  getshowCtl,
} from "../../stored/mydata";
import setdatagang from "../../api/setData_Gang";
import apipostFavorite from "../../api/controller/postFavorite";
import apigetDevice from "../../api/controller/getDevice";
// import { socket } from "../../socket";

export default function Controller(props) {
  var navigate = useNavigate();
  const dispatch = useDispatch();

  const roomid = useSelector(getroomid);
  const myUser = useSelector(getUser);
  const showCtl = useSelector(getshowCtl);

  const [devices, setDevices] = useState([]);
  const [rooms, setRooms] = useState({});
  const [isshow, setIsshow] = useState(false);

  useEffect(() => {
    // socket.on("connect", onConnect);
    // socket.off('disconnect', disconnect);

    var data = {
      room_id: roomid,
    };

    getDevice(data);

    // socket.connect();
    // socket.emit("join", `${myUser.project_code}_${myUser.house_number}`);
    // dispatch(setshowCtlStored(true));
  }, []);

  // socket.on("message", (data) => {
  //   dispatch(setcontrollerStored(data));
  // });

  const getDevice = (data) => {
    apigetDevice(data, (err, res) => {
      if (res["message"] === "Success") {
        setDevices(setdatagang(res["devices"]));
        setRooms(res["room"]);
        setIsshow(true);
      }
    });
  };

  const actionFavorite = (status) => {
    setIsshow(false);

    var data = {
      room_id: roomid,
      status: "",
    };

    if (status === "true") {
      data["status"] = "false";
    } else {
      data["status"] = "true";
    }

    apipostFavorite(data, (err, res) => {
      if (res["message"] === "Success") {
        reload();
      }
    });
  };

  const reload = () => {
    setIsshow(false);

    var data = {
      room_id: roomid,
    };

    getDevice(data);
  };

  // const onConnect = () => {
  //   console.log("socket On");
  //   socket.connect();
  //   dispatch(setsocketStatusStored(true));
  // };

  // const disconnect = () => {
  //   // console.clear();
  //   console.log("socket Off");
  //   socket.disconnect();
  // };

  return (
    <div>
      {isshow === true ? (
        <div className="parentDiv">
          <div className="childDiv">
            <Spin spinning={showCtl}>
              <div
                className=""
                style={{ backgroundColor: "#FBFBFB", height: "100%" }}
              >
                <div name="head" style={styles.head}>
                  <Row style={{ padding: "13px 16px" }}>
                    <Col span={4}>
                      <div
                        onClick={() => {
                          navigate("/home");
                        }}
                      >
                        {ScenePicture.backpage()}
                      </div>
                    </Col>
                    <Col
                      span={16}
                      style={{
                        textAlign: "center",
                        fontFamily: "Gotham",
                        fontSize: "20px",
                        color: "#3EA8B8",
                        fontWeight: "500",
                      }}
                    >
                      <div>{rooms.room_name}</div>
                    </Col>
                    <Col span={4}>
                      <Row gutter={[23, 16]} style={{ paddingTop: "2px" }}>
                        {rooms.favorite === "true" ? (
                          <Col
                            span={12}
                            onClick={() => {
                              actionFavorite(rooms.favorite);
                            }}
                          >
                            {ControllerPicture.heartF()}
                          </Col>
                        ) : (
                          <Col
                            span={12}
                            onClick={() => {
                              actionFavorite(rooms.favorite);
                            }}
                          >
                            {ControllerPicture.heart()}
                          </Col>
                        )}
                        <Col
                          span={12}
                          onClick={() => {
                            reload();
                          }}
                        >
                          {ControllerPicture.refresh()}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <div
                  style={{
                    padding: "24px 16px 16px 16px",
                    background: "#FBFBFB",
                  }}
                >
                  <Row gutter={[16, 16]}>
                    {devices.map((e, i) => {
                      const size = 24;
                      if (e.device_type === "erv") {
                        return (
                          <Col span={size} key={i + "_device"}>
                            <Ervdevice data={e} />
                          </Col>
                        );
                      } else if (e.device_type === "doas") {
                        return (
                          <Col span={size} key={i + "_device"}>
                            <Doasdevice data={e} />
                          </Col>
                        );
                      } else if (e.device_type === "ac") {
                        if (e.device_model === "cassette") {
                          return (
                            <Col span={size} key={i + "_device"}>
                              <AirCassettedevice data={e} />
                            </Col>
                          );
                        } else if (e.device_model === "cassette2") {
                          return (
                            <Col span={size} key={i + "_device"}>
                              <AirCassette2device data={e} />
                            </Col>
                          );
                        } else if (e.device_model === "daikin-coolthing") {
                          return (
                            <Col span={size} key={i + "_device"}>
                              <AirCoolthingdevice data={e} />
                            </Col>
                          );
                        } else if (
                          e.device_model === "AirController-AllInOne"
                        ) {
                          // air controller all in one
                          return (
                            <Col span={size} key={i + "_device"}>
                              <AirAllInOne data={e} />
                            </Col>
                          );
                        } else if (
                          e.device_model === "AirController-Condition"
                        ) {
                          // air controller condition
                          return (
                            <Col span={size} key={i + "_device"}>
                              <AirCondition data={e} />
                            </Col>
                          );
                        }
                      } else if (e.device_type === "lights") {
                        if (e.device_model === "wulian") {
                          return (
                            <Col span={12} key={i + "_device"}>
                              <Lampdevice data={e} />
                            </Col>
                          );
                        } else if (e.device_model === "dim") {
                          // lighting controller dim
                          return (
                            <Col span={12} key={i + "_device"}>
                              <LightingDim data={e} />
                            </Col>
                          );
                        }
                      } else if (e.device_type === "chandelier") {
                        return (
                          <Col span={12} key={i + "_device"}>
                            <Chandelierdevice data={e} />
                          </Col>
                        );
                      } else if (e.device_type === "curtains") {
                        return (
                          <Col span={24} key={i + "_device"}>
                            <Curtaindevice data={e} />
                          </Col>
                        );
                      } else if (e.device_type === "pir") {
                        return (
                          <Col span={24} key={i + "_device"}>
                            <MotionSenserdevice data={e} />
                          </Col>
                        );
                      } else if (e.device_type === "freshairNew") {
                        return (
                          <Col span={24} key={i + "_device"}>
                            <Freshairdevice data={e} />
                          </Col>
                        );
                      } else if (e.device_type === "contact") {
                        return (
                          <Col span={24} key={i + "_device"}>
                            <DoorContactSensordevice data={e} />
                          </Col>
                        );
                      }
                      return <></>;
                    })}
                  </Row>
                </div>
              </div>
            </Spin>
          </div>
        </div>
      ) : null}
    </div>
  );
}

const styles = {
  head: {
    backgroundColor: "#F0F9FB",
    height: "56px",
  },
  reload: {
    margin: "20px 0",
    marginBottom: "20px",
    padding: "30px 50px",
    textAlign: "center",
    borderRadius: "4px",
    display: "inherit",
  },
};
