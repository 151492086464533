import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
// import ScenePicture from "../../components/picture/sceneicon";
// import RoomPicture from "../../components/picture/room";
import { Row, Col, Image, Input, Spin, Modal, Button } from "antd";
import ScenePicture from "../../components/picture/sceneicon";
import Scene_edit_device from "../../components/sceneedit/scene_edit_device";

import apiDeleteScene from "../../api/scene/deleteScene";
// import SceneEditView from "../../components/sceneedit/scene_edit";
// import SceneEditDevice from "../../components/sceneedit/scene_edit_device";
// import apiSceneRoom from "../../api/scene/getSceneRoom";
// import apiSceneDevice from "../../api/scene/getSceneDevice";

import { getUser } from "../../stored/mydata";
import apigetSceneId from "../../api/scene/getSceneId";
import apigetIcons from "../../api/scene/getIcons";
import apipostEditScene from "../../api/scene/postEditScene";

export default function SceneEdit(props) {
  const Params = useParams();
  var navigate = useNavigate();

  const windowHeight = useRef(window.innerHeight);

  const myUser = useSelector(getUser);
  // var navigate = useNavigate();

  const [btnheight, setBtnheight] = useState(430);
  const [scene, setScene] = useState({
    id: 0,
    scene_name: "",
    icon_id: "",
  });
  const [roomslist, setRoomsList] = useState([]);
  // const [room, setRoom] = useState()
  const [nameHead, setNameHead] = useState("");
  const [modelDelete, setModalDelete] = useState(false);

  const [icons, setIcons] = useState([]);
  const [is_device, setIsdevice] = useState(false);
  const [is_lodeing, setIsloading] = useState(false);
  const [obj_device, setOBJdevice] = useState({
    id: 0,
    room_name: "",
    path: "",
    device: [],
  });

  useEffect(() => {
    getIcons();
  }, []);

  const getIcons = () => {
    var data = {
      scenes: "scenes",
    };

    apigetIcons(data, (err, res) => {
      if (res.message === "Success") {
        setIcons(res.icons);
        getSceneId();
        // setStep(1);
      }
    });
  };

  const getSceneId = () => {
    var data = {
      house_number: myUser.house_number,
      project_id: myUser.project_code,
      unit_number: myUser.unit_number,
      scene_id: Params.roomid,
    };

    apigetSceneId(data, (err, res) => {
      if (res.message === "Success") {
        setScene(res.scene);
        setRoomsList(res.rooms);
        setNameHead(res.scene.scene_name);
        settingHeight(res.rooms.length);
      }
    });
  };

  const settingHeight = (data) => {
    var distance = 0;
    var count = 0;

    if (data < 8) {
      count = data;

      if (windowHeight.current > 850) {
        distance = windowHeight.current - 390;
      } else {
        distance = windowHeight.current - 370;
      }
      count = count * 55;
      distance = distance - count;
      setBtnheight(distance);
      setIsloading(true);
    }
  };

  const Set_data = (edit) => {
    // console.log("edit", edit);
    var tmp = roomslist.map((e) => {
      if (e.id === edit.id) {
        return edit;
      } else {
        return e;
      }
    });
    setRoomsList([...tmp]);
    postEditScene(tmp);
    // createDeviceScene(tmp);
  };

  const postEditScene = (tmp) => {
    var data = JSON.stringify({
      scene_id: Params.roomid,
      scene_name: scene.scene_name,
      icon_id: scene.icon_id,
      rooms: tmp,
    });

    apipostEditScene(data, (err, res) => {
      if (res.message === "Success") {
        // navigate("/scene");
        setIsdevice(!is_device);
      }
    });
  };
  const postEditSceneName = () => {
    var data = JSON.stringify({
      scene_id: Params.roomid,
      scene_name: scene.scene_name,
      icon_id: scene.icon_id,
      rooms: roomslist,
    });

    apipostEditScene(data, (err, res) => {
      if (res.message === "Success") {
        navigate("/scene");
      }
    });
  };

  const deleteScene = (id) => {
    apiDeleteScene(id, (err, res) => {
      if (res.message === "Success") {
        navigate("/scene");
      }
    });
  };

  const stopModal = () => {
    setModalDelete(false);
  };
  const startModal = () => {
    setModalDelete(true);
  };

  if (is_device) {
    return (
      <Scene_edit_device
        data={obj_device}
        handleBack={() => {
          setIsdevice(!is_device);
          setOBJdevice({});
        }}
        handleUpdated={(event) => {
          Set_data(event);
          setIsdevice(!is_device);
          setOBJdevice({});
        }}
      />
    );
  } else {
    return (
      <div className="parentDiv">
        <div className="childDiv">
          <div style={{ backgroundColor: "#FBFBFB", height: "100%" }}>
            <div name="head" style={styles.head}>
              <Row style={{ padding: "13px 16px" }}>
                <Col span={4}>
                  <div
                    onClick={() => {
                      navigate("/scene");
                    }}
                  >
                    {ScenePicture.backpage()}
                  </div>
                </Col>
                <Col span={16} style={styles.textHead}>
                  <div>{nameHead} scene</div>
                </Col>
                <Col span={4}></Col>
              </Row>
            </div>
            <div style={styles.bodyPage}>
              <div style={styles.textScene}>Scene</div>
              <div>
                <Row>
                  <Col span={7}>
                    <div style={styles.boxPicture}>
                      <div style={{ margin: "15px 5px 0 5px" }}>
                        <div style={{ textAlign: "center" }}>
                          {icons.map((e) => {
                            if (parseInt(scene.icon_id) === e.id) {
                              return (
                                <Image
                                  preview={false}
                                  width={40}
                                  src={e.name}
                                />
                              );
                            }
                            return <></>;
                          })}
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            whiteSpace: "nowrap",
                            // width: "50px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {scene["scene_name"] !== ""
                            ? scene["scene_name"]
                            : "Name"}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col span={17}>
                    <div style={{ borderBottom: "1px solid #C0C0C0" }}>
                      <Input
                        placeholder="Name"
                        bordered={false}
                        value={scene.scene_name}
                        onChange={(e) => {
                          setScene({
                            ...scene,
                            scene_name: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div
                      style={{
                        overflow: "auto",
                        display: "flex",
                        marginTop: "10px",
                      }}
                    >
                      {icons.map((e, i) => {
                        var colorBg = styles.borderIcon;
                        if (e.id === parseInt(scene.icon_id)) {
                          colorBg = styles.borderIconAction;
                        }
                        return (
                          <div style={styles.boxIcon} key={i + "_icon"}>
                            <div
                              style={colorBg}
                              onClick={() => {
                                setScene({
                                  ...scene,
                                  icon_id: e["id"].toString(),
                                });
                              }}
                            >
                              <Image preview={false} width={40} src={e.name} />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
              </div>
              <div style={{ marginTop: "20px" }}>
                {roomslist.map((e, i) => {
                  return (
                    <div>
                      <Row
                        key={i + "_scene"}
                        gutter={[20, 8]}
                        style={{
                          height: "20px",
                          marginBottom: "8%",
                          padding: "0px 8px",
                        }}
                        onClick={() => {
                          setOBJdevice(e);
                          setIsdevice(!is_device);
                        }}
                      >
                        <Col span={3} style={styles.itemInRowStart}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              width: "35px",
                            }}
                          >
                            <Image preview={false} width={40} src={e.path} />
                          </div>
                        </Col>
                        <Col span={18} style={styles.itemInRow}>
                          <div style={styles.textInItem}>{e.room_name}</div>
                        </Col>
                        <Col
                          span={3}
                          style={{ textAlign: "end", paddingTop: "15px" }}
                        >
                          <div>{ScenePicture.more_than()}</div>
                        </Col>
                      </Row>
                      <hr style={styles.underlineSchedule}></hr>
                    </div>
                  );
                })}
              </div>

              <div
                style={{
                  marginTop: btnheight,
                  textAlign: "-webkit-center",
                }}
              >
                <div>
                  <button
                    style={styles.btnSave}
                    onClick={() => {
                      postEditSceneName();
                    }}
                  >
                    SAVE CHANGES
                  </button>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <button
                    style={styles.btnDelete}
                    onClick={() => {
                      startModal();
                    }}
                  >
                    DELETE SCENE
                  </button>
                </div>
              </div>
            </div>
          </div>

          <Modal
            centered={true}
            open={modelDelete}
            onCancel={stopModal}
            footer={null}
            closable={false}
          >
            <div style={{ display: "grid" }}>
              <div style={styles.textHeadmodal}>Delete this scene?</div>
              <div style={styles.textBodymodal}>
                This is confirmation for deleting this scene
              </div>
              <Row>
                <Col span={12} style={{ display: "inline-grid" }}>
                  <Button
                    onClick={() => {
                      stopModal();
                    }}
                    style={{
                      backgroundColor: "#ffffff",
                      borderColor: "#ffffff",
                      color: "#919499",
                      fontSize: "15px",
                      fontWeight: "400",
                      height: "45px",
                      textTransform: "uppercase",
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col span={12} style={{ display: "inline-grid" }}>
                  <Button
                    onClick={() => {
                      deleteScene(scene.id);
                    }}
                    style={{
                      backgroundColor: "#ffffff",
                      borderColor: "#ffffff",
                      color: "#F00",
                      fontSize: "15px",
                      fontWeight: "400",
                      height: "45px",
                      textTransform: "uppercase",
                    }}
                  >
                    Delete
                  </Button>
                </Col>
              </Row>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

const styles = {
  head: {
    backgroundColor: "#F0F9FB",
    height: "56px",
  },
  textHead: {
    color: "#3EA8B8",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    fontFamily: "Gotham",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "350",
    lineHeight: "30px",
    letterSpacing: "0.15px",
    whiteSpace: "nowrap",
    width: "100px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  bodyPage: {
    backgroundColor: "#FBFBFB",
    padding: "20px 15px 20px 15px",
    // height: "800px",
  },
  textScene: {
    color: "rgba(86, 88, 93, 1)",
    fontFeatureSettings: "clig off, liga off",
    fontFamily: "Gotham",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "350",
    lineHeight: "24px" /* 150% */,
    letterSpacing: "0.4px",
  },
  itemInRow: {
    display: "flex",
    alignItems: "center",
  },
  textInItem: {
    color: "#56585D",
    fontFeatureSettings: "clig off, liga off",
    fontFamily: "Gotham",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px" /* 131.25% */,
  },
  itemInRowStart: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "10px",
  },
  floating_button_div: {
    position: "fixed",
    bottom: "20px",
    // float: 'left',
    // width: '33%',
    // alignItems: "center",
    right: "60px",
  },
  btnSave: {
    display: "flex",
    width: "312px",
    height: "48px",
    padding: "9px 0px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
    borderRadius: "8px",
    border: "1px solid #3EA8B8",
    backgroundColor: "white",
    background: "#3EA8B8",
    color: "rgba(251, 251, 251, 1)",
  },
  btnDelete: {
    display: "flex",
    width: "312px",
    height: "48px",
    padding: "9px 0px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
    borderRadius: "8px",
    border: "1px solid rgba(255, 0, 0, 1)",
    backgroundColor: "white",
    // background: "#919499",
    color: "rgba(255, 0, 0, 1)",
  },
  boxPicture: {
    width: "96px",
    height: "96px",
    flexShrink: "0",
    borderRadius: "16px",
    border: "1px solid var(--primary-meadows-100, #B5EAEF)",
    background: "var(--text-on-dark-area",
  },
  boxIcon: {
    width: "52px",
    height: "52px",
    margin: "0 5px",
  },
  borderIcon: {
    width: "42px",
    height: "42px",
    border: "1px solid var(--primary-meadows-100, #B5EAEF)",
    borderRadius: "50%",
    display: "inline-block",
    backgroundColor: "#FFF",
  },
  borderIconAction: {
    width: "42px",
    height: "42px",
    border: "1px solid var(--primary-meadows-100, #B5EAEF)",
    borderRadius: "50%",
    display: "inline-block",
    backgroundColor: "#EBF4FA",
  },
  textHeadmodal: {
    color: "var(--text-on-light-area, #56585D)",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    fontFamily: "Gotham",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "24px",
    letterSpacing: "0.4px",
  },
  textBodymodal: {
    color: "var(--text-on-light-area, #56585D)",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    fontFamily: "Gotham",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "24px",
    letterSpacing: "0.5px",
  },
  reload: {
    margin: "20px 0",
    marginBottom: "20px",
    padding: "30px 50px",
    textAlign: "center",
    borderRadius: "4px",
    display: "inherit",
  },
  underlineSchedule: {
    backgroundColor: "#919499",
    opacity: "0.2",
    marginTop: "10px",
  },
};
