import React, { useState } from "react";
import { Image, Modal, ConfigProvider } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

import apipostActiveScene from "../../api/scene/postActiveScene";

export default function Scenes(props) {
  const [scenes, setScenes] = useState(props.data);
  const [modelActive, setModelActive] = useState(false);

  const postActiveScene = (id) => {
    var data = JSON.stringify({
      scene_id: id.toString(),
      cmd: "active",
    });

    apipostActiveScene(data, (err, res) => {
      if (res.message === "Success") {
        startModal();
        // setScenes({ ...scenes, active: "true" });
        var count_time = 3;
        let timerId = setInterval(() => {
          count_time--;

          if (count_time === 0) {
            stopModal();
            // setScenes({ ...scenes, active: "false" });
            clearInterval(timerId);
          }
        }, 1000);
      }
    });
  };

  const stopModal = () => {
    setModelActive(false);
  };
  const startModal = () => {
    setModelActive(true);
  };

  return (
    <div>
      {scenes.active === "false" ? (
        <div
          style={styles.boxCard}
          onClick={() => {
            postActiveScene(scenes.id);
          }}
        >
          <div style={{ paddingTop: "14px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Image preview={false} width={40} src={scenes.icon_url} />
            </div>
          </div>
          <div style={{ marginTop: "8px" }}>
            <div style={styles.textName}>{scenes.scene_name}</div>
          </div>
        </div>
      ) : (
        <div
          style={styles.boxCardActive}
          onClick={() => {
            postActiveScene(scenes.id);
          }}
        >
          <div style={{ paddingTop: "14px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Image preview={false} width={40} src={scenes.icon_url} />
            </div>
          </div>
          <div style={{ marginTop: "8px" }}>
            <div style={styles.textName}>{scenes.scene_name}</div>
          </div>
        </div>
      )}

      <ConfigProvider
        theme={{
          token: {
            boxShadow: "none !important",
          },
          components: {
            Modal: {
              contentBg: "transparent !important",
            },
          },
        }}
      >
        <Modal
          centered={true}
          open={modelActive}
          // onCancel={stopModal}
          footer={null}
          closable={false}
          width={200}
          style={{
            // backgroundColor: "transparent",
            // boxShadow: "none",
            textAlign: "center",
          }}
        >
          <div>
            <div style={styles.textModelH}>{scenes.scene_name} Scene
              {/* <span style={styles.textNameH}>{scenes.scene_name}</span>{" "}
              <span style={styles.textModelH}>Scene</span> */}
            </div>
            <div style={styles.textModelB}>Active</div>
            <div style={{ padding: "6px" }}>
              <CheckCircleOutlined
                style={{ fontSize: "40px", color: "#FFFF" }}
              />
            </div>
          </div>
        </Modal>
      </ConfigProvider>
    </div>
  );
}

const styles = {
  boxCard: {
    borderRadius: "16px",
    height: "85px",
    flexShrink: "0",
    backgroundColor: "#eaf5fc",
    padding: "7px",
    fontFamily: "Gotham",
  },
  boxCardActive: {
    borderRadius: "16px",
    height: "85px",
    flexShrink: "0",
    backgroundColor: "#eaf5fc",
    padding: "7px",
    fontFamily: "Gotham",
    // borderStyle: "solid",
    // borderColor: "#3EA8B8",
  },
  textName: {
    color: "#3EA8B8",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "12px",
    letterSpacing: "0.2px",
    textTransform: "uppercase",
    fontFamily: "Gotham",

    whiteSpace: "nowrap",
    width: "92px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  // textNameH: {
  //   color: "#FFFF",
  //   textAlign: "center",
  //   fontFeatureSettings: "clig off, liga off",
  //   fontSize: "16px",
  //   fontStyle: "normal",
  //   fontWeight: "400",
  //   lineHeight: "12px",
  //   letterSpacing: "0.2px",
  //   textTransform: "uppercase",
  //   fontFamily: "Gotham",

  //   whiteSpace: "nowrap",
  //   width: "92px",
  //   overflow: "hidden",
  //   textOverflow: "ellipsis",
  // },
  textModelH: {
    color: "#FFFF",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    fontFamily: "Gotham",
  },
  textModelB: {
    color: "#FFFF",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    fontFamily: "Gotham",
    padding: "1px",
  },
};
