import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Switch, Space, DatePicker } from "antd";
import ControllerPicture from "../../components/picture/controller";
import apipostContact from "../../api/controller/postContact";

import { getcontroller, setshowCtlStored } from "../../stored/mydata";

export default function Doorcontactsensor(props) {
  const dispatch = useDispatch();

  const dataController = useSelector(getcontroller);
  const [dataDevice, setDataDevice] = useState({});

  useEffect(() => {
    setDataDevice(props.data);
  }, []);

  useEffect(() => {
    if (
      dataDevice["mac"] === dataController["mac"] &&
      dataDevice["device_id"] === dataController["device_id"]
    ) {
      // setDataDevice({...dataDevice, device_endpoint_status: dataController["device_endpoint_status"]});
      dataDevice["device_endpoint_status"] = dataController["device_endpoint_status"];
    }
  }, [dataController]);

  const apiControll = (value) => {
    dispatch(setshowCtlStored(true));

    const data_api = JSON.stringify(value);

    apipostContact(data_api, (err, res) => {
      if (res["message"] === "Success") {
        var count_time = 3;
        let timerId = setInterval(() => {
          count_time--;

          if (count_time === 0) {
            dispatch(setshowCtlStored(false));
            clearInterval(timerId);
          }
        }, 1000);
      }
    });
  };

  const controllerSwitch = (e) => {
    var data = {
      mac: dataDevice["mac"],
      device_id: dataDevice["device_id"],
      device_model: dataDevice["device_model"],
      device_type: dataDevice["device_type"],
      status: "",
    };

    if (e) {
      data["status"] = "on";
    } else {
      data["status"] = "off";
    }

    apiControll(data);
  };

  return (
    <div>
      <div style={dataDevice["device_endpoint_status"] === "on" ? styles.card : styles.cardoff}>
        <Row>
          <Col span={18}>
            <Row>
              <Col span={3} style={{ width: "150px" }}>
                { dataDevice["device_endpoint_status"] === "on" ?  ControllerPicture.doorcontactsensor() : ControllerPicture.doorcontactsensoroff() }
              </Col>
              <Col span={21}>
                <div style={dataDevice["device_endpoint_status"] === "on" ? styles.fontInpage : styles.fontInpageoff}>{dataDevice["device_name"]}</div>
              </Col>
            </Row>
          </Col>
          <Col span={6} style={{ textAlign: "end", paddingTop: "3px" }}>
            <Space direction="vertical">
              <Switch
                checkedChildren="ON"
                unCheckedChildren="OFF"
                onChange={controllerSwitch}
                checked={dataDevice["device_endpoint_status"] === "on" ? true : false}
              />
            </Space>
          </Col>
        </Row>
      </div>
    </div>
  );
}

const styles = {
  head: {
    backgroundColor: "#F0F9FB",
    height: "108px",
  },
  card: {
    display: "flex",
    padding: "16px",
    flexDirection: "column",
    gap: "12px",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
  },
  fontInpage: {
    color: "#3EA8B8",
    paddingTop: "2px",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px" /* 150% */,
  },
  fontNumber: {
    color: "#919499",
    textAlign: "center",
    fontFamily: "Gotham",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
  },

  cardoff: {
    display: "flex",
    padding: "16px",
    flexDirection: "column",
    gap: "12px",
    borderRadius: "16px",
    border: "1px solid #E3E3E3",
  },
  fontInpageoff: {
    color: "#E3E3E3",
    paddingTop: "2px",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px" /* 150% */,
  },
};
