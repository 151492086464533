const energy = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g clipPath="url(#clip0_107_4715)">
          <path
            d="M16.6947 10.5752C16.5789 10.3603 16.361 10.221 16.1176 10.2066L12.4877 9.98855L13.586 4.62152C13.6548 4.28392 13.4689 3.94561 13.1467 3.82293C12.8234 3.70128 12.4598 3.83 12.2882 4.129L7.31638 12.7405C7.1945 12.9516 7.19068 13.2106 7.30574 13.4251C7.42161 13.64 7.6395 13.7792 7.88289 13.7936L11.5127 14.0117L10.4144 19.3787C10.3457 19.7163 10.5316 20.0546 10.8538 20.1773C11.176 20.2986 11.5391 20.1709 11.7123 19.8712L16.6841 11.2598C16.806 11.0487 16.8098 10.7896 16.6947 10.5752Z"
            fill="#3EA8B8"
          />
          <circle cx="12" cy="12" r="11.35" stroke="#3EA8B8" strokeWidth="1.3" />
        </g>
        <defs>
          <clipPath id="clip0_107_4715">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  };
  const energys = {
    energy
  }
  export default  energys;