import React, { useEffect, useState } from "react";
import Main from '../../container/schedule/editSchedule'
export default function AddSchedule(props) {
    return (
        <Main />
    );
}

const styles = {

};
