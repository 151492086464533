import React from "react";
import ReactApexChart from "react-apexcharts";

const EnergyChart = (props) => {

  const chartOptions = {
    series: [
      {
        name: "",
        data: props.dataemu['datas'],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },
      colors: ["#B8DBE2"],
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: props.dataemu['data_categories'],
        labels: {
          style: {
            colors: ["#56585D"],
            fontSize: "12px",
          },
        },
      },
      
    //   grid: {
    //     show: true,
    //     borderColor: '#90A4AE',
    //     strokeDashArray: 0,
    //     position: 'back',
    //     xaxis: {
    //         lines: {
    //             show: false
    //         }
    //     },   
    //     yaxis: {
    //         lines: {
    //             show: false
    //         }
    //     },  
    //     row: {
    //         colors: undefined,
    //         opacity: 0.5
    //     },  
    //     column: {
    //         colors: undefined,
    //         opacity: 0.5
    //     },  
    //     padding: {
    //         top: 0,
    //         right: 0,
    //         bottom: 0,
    //         left: 0
    //     },  
    // }
      // tooltip: {
      //   y: {
      //     formatter: function(val) {
      //       return ''
      //     },
      //     title: {
      //       formatter: function (seriesName) {
      //         return seriesName
      //       }
      //     }
      //   }
      // }
    },
  };

  return (
    <div>
      <ReactApexChart
        options={chartOptions['options']}
        series={chartOptions['series']}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default EnergyChart;
