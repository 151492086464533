import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Switch,
  Input,
  Select,
  Radio,
  DatePicker,
  Spin,
  Image,
  // Checkbox,
  Modal,
  Layout,
  FloatButton,
  ConfigProvider,
} from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { getUser, getsceneAll } from "../../stored/mydata";
import apigetIcons from "../../api/scene/getIcons";
import apigetScene from "../../api/scene/getScene";
import apigetScheduleid from "../../api/schedule/getScheduleId";
import apipostEditSchedule from "../../api/schedule/postEditSchedule";
import apideleteSchedule from "../../api/schedule/deleteSchedule";
// import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons";
// import scheduleicon from "../../components/picture/scheduleicon";
import Devicebox from "../../components/addschdule/devicebox";
import Room_deive from "../../components/schedule/schedule_edit_device";
import ScenePicture from "../../components/picture/sceneicon";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";

const { Header, Content, Footer } = Layout;
// import Room_deive from "./room_device"
export default function ScheduleNew(props) {
  const Params = useParams();
  const myUser = useSelector(getUser);
  const myScenes = useSelector(getsceneAll);

  const windowWidth = useRef(window.innerWidth);

  const [modelDelete, setModalDelete] = useState(false);
  const [defaultTime, setDefaulTime] = useState(moment().startOf("day"));

  const [is_device, setIsdevice] = useState(false);
  // const [is_lodeing, setIsloading] = useState(false);
  const [icons, setIcon] = useState([]);
  const [obj_device, setOBJdevice] = useState({
    id: 0,
    name: "",
    icon: "",
    data: [],
  });
  const [data, setData] = useState({
    schedule_mode: "sunrise",
    active: false,
    schedule_name: "",
    icon: "sun",
    icon_id: "6",
    status: true,
    schedule_type: "",
    schedule_day: [1, 2, 3, 4, 5, 7],
    schedule_time: "00:00",
    devices: [
      { id: 1, name: "Kitchen", icon: "", data: [] },
      { id: 2, name: "Living Room", icon: "", data: [] },
      { id: 3, name: "Master Bathroom", icon: "", data: [] },
    ],
    scene: "",
  });
  const [checkedActive, setCheckedActive] = useState(false);
  const [selectedKey, setSelectKey] = useState("device");
  // const [sceneId, setSceneid] = useState([]);
  const [scene, setScene] = useState([]);
  // const [defaultCb, setDefaultcb] = useState(["aa"]);
  const [defaultSe, setDefaultse] = useState("no data");
  const [isshow, setIsshow] = useState(false);
  const [btnwidth, setBtnwidth] = useState(0); //380
  const [optionsScenes, setOptionsScenes] = useState([
    {
      label: "Movie",
      value: "movie",
    },
  ]);
  const [devices, setDevices] = useState([
    {
      id: 1,
      name: "Kitchen",
      icon: "",
      data: [
        {
          id: "125313",
          type: {
            name: "erv",
          },
          mode: "auto",
          fan: "low",
          status: "on",
        },
      ],
    },
  ]);

  var navigate = useNavigate();

  useEffect(() => {
    getIcons();
    getScheduleId();
    getScene();
  }, []);

  const getScheduleId = () => {
    var data = {
      schedule_id: Params.schduleid,
      house_number: myUser.house_number,
      project_id: myUser.project_code,
      unit_number: myUser.unit_number,
    };
    apigetScheduleid(data, (err, res) => {
      if (res.message === "Success") {
        var set_data = res.schedule;

        if (set_data.schedule_time !== "") {
          setDefaulTime(dayjs(set_data.schedule_time, "hh:mm"));
        }
        
        if (set_data.active === "true") {
          setCheckedActive(true);
        }
        setData(set_data);
        defaultScene(set_data["schedule_scene_id"]);
        setSelectKey(set_data["schedule_type"]);
        setDevices(res.rooms);
      }
    });
  };

  const getIcons = () => {
    var data = {
      scenes: "scene",
    };

    apigetIcons(data, (err, res) => {
      if (res.message === "Success") {
        setIcon(res.icons);
      }
    });
  };

  const getScene = () => {
    var raw = {
      house_number: myUser.house_number,
      project_id: myUser.project_code,
      unit_number: myUser.unit_number,
    };

    apigetScene(raw, (err, res) => {
      if (res.message === "Success") {
        var scenes_all = res.scenes;
        var plain = [];
        scenes_all.map((e) => {
          plain.push({
            label: e.scene_name,
            value: e.scene_name,
          });
        });
        setScene(scenes_all);
        setOptionsScenes(plain);
      }
    });
  };

  const deleteSchedule = (id) => {
    apideleteSchedule(id, (err, res) => {
      if (res.message === "Success") {
        navigate("/schdule");
      }
    });
  };

  const defaultScene = (data) => {
    for (let i = 0; i < data.length; i++) {
      myScenes.map((e) => {
        if (e.id === data[i]) {
          setDefaultse(e.scene_name);
        }
      });
    }

    settingWidth();
    // setIsshow(true);
  };

  const handleChange = (value) => {
    var scene_id = [];
    scene.map((e) => {
      if (e.scene_name === value) {
        scene_id.push(e.id);
      }
    });
    // setSceneid(scene_id);
    setData({ ...data, schedule_scene_id: scene_id });
  };

  const stopModal = () => {
    setModalDelete(false);
  };
  const startModal = () => {
    setModalDelete(true);
  };

  const settingWidth = () => {
    var btn_width = 0;

    if (windowWidth.current <= 400) {
      btn_width = windowWidth.current - 350;
    } else if (windowWidth.current <= 430) {
      btn_width = windowWidth.current - 370;
    } else if (windowWidth.current <= 768) {
      btn_width = windowWidth.current - 530;
    } else if (windowWidth.current <= 1024) {
      btn_width = windowWidth.current - 650;
    }
    setBtnwidth(btn_width);
    setIsshow(true);
  };

  const postEditSchedule = () => {
    // var setTime = "";
    // if (data.schedule_time.$H === 0 && data.schedule_time.$m === 0) {
    //   setTime = "00:00";
    // } else if (data.schedule_time.$H > 0 && data.schedule_time.$m === 0) {
    //   setTime = data.schedule_time.$H + ":00";
    // } else if (data.schedule_time.$H === 0 && data.schedule_time.$m > 0) {
    //   setTime = "00:" + data.schedule_time.$m;
    // } else if (data.schedule_time.$H > 0 && data.schedule_time.$m > 0) {
    //   var date_hour = "";
    //   var date_minute = "";

    //   if (data.schedule_time.$H < 10) {
    //     date_hour = "0" + data.schedule_time.$H;
    //   } else if (data.schedule_time.$H > 9) {
    //     date_hour = data.schedule_time.$H;
    //   }

    //   if (data.schedule_time.$m < 10) {
    //     date_minute = "0" + data.schedule_time.$m;
    //   } else if (data.schedule_time.$m > 9) {
    //     date_minute = data.schedule_time.$m;
    //   }
    //   setTime = date_hour + ":" + date_minute;
    // }

    var raw = JSON.stringify({
      active: data.active.toString(),
      schedule_id: data.id.toString(),
      schedule_name: data.schedule_name,
      icon_id: data.icon_id,
      schedule_mode: data.schedule_mode,
      schedule_time: data.schedule_mode === "time" ? data.schedule_time : "",
      // schedule_time: setTime,
      schedule_day: data.schedule_day,
      schedule_type: data.schedule_type,
      schedule_scene_id: data.schedule_scene_id,
      rooms: devices,
    });

    apipostEditSchedule(raw, (err, res) => {
      if (res.message === "Success") {
        navigate("/schdule");
      }
    });
  };

  const Set_data = (edit) => {
    var tmp = devices.map((e) => {
      if (e.id === edit.id) {
        return edit;
      } else {
        return e;
      }
    });
    setDevices([...tmp]);
  };
  if (is_device) {
    return (
      <Room_deive
        data={obj_device}
        handleBack={() => {
          setIsdevice(!is_device);
          setOBJdevice({});
        }}
        handleUpdated={(event) => {
          Set_data(event);
          setIsdevice(!is_device);
          setOBJdevice({});
        }}
      />
    );
  } else {
    return (
      <Layout>
        <Content>
          <div className="parentDiv">
            <div className="childDiv">
              {isshow ? (
                <div style={{ backgroundColor: "#FBFBFB", height: "100%" }}>
                  <div name="head" style={styles.head}>
                    <Row style={{ padding: "13px 16px" }}>
                      <Col span={4}>
                        <div
                          onClick={() => {
                            navigate("/schdule");
                          }}
                        >
                          {ScenePicture.backpage()}
                        </div>
                      </Col>
                      <Col span={16} style={styles.textHead}>
                        <div>Edit schedule</div>
                      </Col>
                      <Col span={4}></Col>
                    </Row>
                  </div>

                  <div style={styles.bodyPage}>
                    <Row
                      align={"middle"}
                      justify={"space-between"}
                      style={{ margin: 20 }}
                    >
                      <Col>
                        <span style={styles.textSchedule}>Schedule</span>
                      </Col>
                      <Col>
                        <Switch
                          // direction="vertical"
                          checkedChildren="ON"
                          unCheckedChildren="OFF"
                          checked={checkedActive}
                          onChange={(e) => {
                            setCheckedActive(e);
                            if (e) {
                              setData({ ...data, active: "true" });
                            } else {
                              setData({ ...data, active: "false" });
                            }
                          }}
                        />
                        &nbsp; &nbsp;
                        <span style={{ fontSize: "0.875rem", fontWeight: 500 }}>
                          {data.active === "true" ? "Active" : "Inactive"}
                        </span>
                      </Col>
                    </Row>

                    <div style={{ marginTop: "16px" }}>
                      <Row>
                        <Col span={7}>
                          <div style={styles.boxPicture}>
                            <div style={{ margin: "16px" }}>
                              <div style={{ textAlign: "center" }}>
                                {icons.map((e) => {
                                  if (parseInt(data.icon_id) === e.id) {
                                    return (
                                      <Image
                                        preview={false}
                                        width={40}
                                        src={e.name}
                                      />
                                    );
                                  }
                                  return <></>;
                                })}
                              </div>
                              <div
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "nowrap",
                                  // width: "50px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {data["schedule_name"] !== ""
                                  ? data["schedule_name"]
                                  : "Name"}
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col span={17}>
                          <div style={{ borderBottom: "1px solid #C0C0C0" }}>
                            <Input
                              placeholder="Name"
                              bordered={false}
                              value={data.schedule_name}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  schedule_name: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div
                            style={{
                              overflow: "auto",
                              display: "flex",
                              marginTop: "10px",
                            }}
                          >
                            {icons.map((e, i) => {
                              var colorBg = styles.borderIcon;
                              if (e.id === parseInt(data.icon_id)) {
                                colorBg = styles.borderIconAction;
                              }
                              return (
                                <div style={styles.boxIcon} key={i + "_icon"}>
                                  <div
                                    style={colorBg}
                                    onClick={() => {
                                      setData({
                                        ...data,
                                        icon_id: e["id"].toString(),
                                      });
                                    }}
                                  >
                                    <Image
                                      preview={false}
                                      width={40}
                                      src={e.name}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <Row
                      style={{
                        marginTop: "32px",
                        marginBottom: "32px",
                      }}
                    >
                      <Col span={10}>
                        <Select
                          disabled={true}
                          defaultValue={selectedKey}
                          style={{
                            width: 110,
                          }}
                          options={[
                            {
                              label: "Device",
                              value: "device",
                            },
                            {
                              label: "Scene",
                              value: "scene",
                            },
                          ]}
                          onChange={(e) => {
                            if (e === "device") {
                              setData({ ...data, scene: "" });
                            } else {
                              setData({ ...data, devices: [] });
                            }
                            setSelectKey(e);
                          }}
                        />{" "}
                        &nbsp; :
                      </Col>
                      {selectedKey === "scene" && optionsScenes.length > 0 ? (
                        <Col span={14}>
                          <Select
                            defaultValue={defaultSe}
                            style={{
                              width: "100%",
                            }}
                            onChange={handleChange}
                            options={optionsScenes}
                          />
                        </Col>
                      ) : null}
                    </Row>
                    {selectedKey === "device" &&
                      devices.map((d) => (
                        <Devicebox
                          data={d}
                          handleSet={(data) => {
                            setOBJdevice(data);
                            setIsdevice(!is_device);
                          }}
                        />
                      ))}
                    <div
                      style={{
                        marginBottom: "16px",
                        color: "rgba(86, 88, 93, 1)",
                        fontFeatureSettings: "clig off, liga off",
                        fontFamily: "Gotham",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "24px",
                        letterSpacing: "0.4px",
                      }}
                    >
                      Set Schedule
                    </div>

                    <div style={{ margin: "10px 0px 15px 0px" }}>
                      <Radio.Group
                        value={data.schedule_mode}
                        onChange={(e) => {
                          setData({ ...data, schedule_mode: e.target.value });
                        }}
                        style={{ display: "flow" }}
                      >
                        <Row>
                          <Col span={12}>
                            <Radio
                              value={"sunrise"}
                              style={{ marginBottom: 20 }}
                            >
                              <span
                                style={
                                  data.schedule_mode === "sunrise"
                                    ? styles.textTimeChoose
                                    : styles.textTime
                                }
                              >
                                Sunrise
                              </span>
                            </Radio>
                          </Col>
                          <Col span={12}>
                            <Radio value={"sunset"}>
                              <span
                                style={
                                  data.schedule_mode === "sunset"
                                    ? styles.textTimeChoose
                                    : styles.textTime
                                }
                              >
                                Sunset
                              </span>
                            </Radio>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={10}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                height: "100%",
                              }}
                            >
                              <Radio value={"time"}>
                                <span
                                  style={
                                    data.schedule_mode === "time"
                                      ? styles.textTimeChoose
                                      : styles.textTime
                                  }
                                >
                                  Specific Time
                                </span>
                              </Radio>
                            </div>
                          </Col>
                          <Col span={14}>
                            <Row>
                              <Col span={12}>
                                <div>
                                  {data.schedule_mode === "time" && (
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <MobileTimePicker
                                        defaultValue={defaultTime}
                                        onChange={(value) => {
                                          setData({
                                            ...data,
                                            schedule_time: moment(
                                              value.$d
                                            ).format("HH:mm"),
                                          });
                                        }}
                                      />
                                    </LocalizationProvider>
                                    // <DatePicker
                                    //   defaultValue={
                                    //     data.schedule_time !== ""
                                    //       ? dayjs(data.schedule_time, "hh:mm")
                                    //       : moment().startOf("day")
                                    //   }
                                    //   size={"large"}
                                    //   picker={"time"}
                                    //   format="HH : mm"
                                    //   onChange={(value) => {
                                    //     setData({ ...data, schedule_time: value });
                                    //   }}
                                    // />
                                  )}
                                </div>
                              </Col>
                              <Col span={12}></Col>
                            </Row>
                          </Col>
                        </Row>
                      </Radio.Group>
                    </div>

                    <div
                      style={{
                        color: "rgba(86, 88, 93, 1)",
                        fontFeatureSettings: "clig off, liga off",
                        fontFamily: "Gotham",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "24px",
                        letterSpacing: "0.4px",
                        marginBottom: "16px",
                      }}
                    >
                      Day
                    </div>
                    <Row gutter={[16, 2]}>
                      {new Array(7).fill(null).map((_, i) => {
                        const id = i + 1;
                        return (
                          <Col span={12} style={{ marginBottom: "16px" }}>
                            <Switch
                              checked={
                                data.schedule_day.find((d) => d === i + 1)
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                if (e) {
                                  setData({
                                    ...data,
                                    schedule_day: data.schedule_day.concat(
                                      i + 1
                                    ),
                                  });
                                } else {
                                  setData({
                                    ...data,
                                    schedule_day: data.schedule_day.filter(
                                      (d) => d !== i + 1
                                    ),
                                  });
                                }
                              }}
                            />{" "}
                            &nbsp; {moment().day(id).format("dddd")}{" "}
                          </Col>
                        );
                      })}
                    </Row>
                    <div style={{ height: "130px" }}></div>

                    <ConfigProvider
                      theme={{
                        token: {
                          colorBgElevated: "#3EA8B8",
                          colorText: "white",
                        },
                      }}
                    >
                      <FloatButton
                        style={{
                          left: `${btnwidth}px`,
                          bottom: "100px",
                          display: "flex",
                          width: "312px",
                          height: "48px",
                          flexShrink: "0",
                          borderRadius: "8px",
                        }}
                        onClick={() => {
                          postEditSchedule();
                        }}
                        description={
                          <div style={styles.textInbtnA}>save changes</div>
                        }
                      />
                    </ConfigProvider>

                    <ConfigProvider
                      theme={{
                        token: {
                          colorBgElevated: "#FFFF",
                          colorText: "#FF0000",
                        },
                      }}
                    >
                      <FloatButton
                        style={{
                          left: `${btnwidth}px`,
                          bottom: "43px",
                          display: "flex",
                          width: "312px",
                          height: "48px",
                          flexShrink: "0",
                          borderRadius: "8px",
                          border: "1px solid #FF0000",
                        }}
                        onClick={() => {
                          startModal();
                        }}
                        description={
                          <div style={styles.textbtnDelete}>
                            delete schedule
                          </div>
                        }
                      />
                    </ConfigProvider>

                    {/* <Row justify={"center"}>
                      <Col>
                        <Button
                          style={styles.btnCreateActton}
                          onClick={() => {
                            postEditSchedule();
                          }}
                        >
                          <div style={styles.textInbtnA}>save changes</div>
                        </Button>
                      </Col>
                    </Row>
                    <Row justify={"center"} style={{ margin: "12px 0 35px 0" }}>
                      <Col>
                        <Button
                          style={styles.btnDeleteActton}
                          onClick={() => {
                            // console.log(data);
                            // console.log(devices);
                            startModal();
                            // data + devices
                          }}
                        >
                          <div style={styles.textbtnDelete}>
                            delete schedule
                          </div>
                        </Button>
                      </Col>
                    </Row> */}
                  </div>
                </div>
              ) : null}

              <Modal
                centered={true}
                open={modelDelete}
                onCancel={stopModal}
                footer={null}
                closable={false}
              >
                <div style={{ display: "grid" }}>
                  <div style={styles.textHeadmodal}>Delete this schedule?</div>
                  <div style={styles.textBodymodal}>
                    This is confirmation for deleting this schedule
                  </div>
                  <Row>
                    <Col span={12} style={{ display: "inline-grid" }}>
                      <Button
                        onClick={() => {
                          stopModal();
                        }}
                        style={{
                          backgroundColor: "#ffffff",
                          borderColor: "#ffffff",
                          color: "#919499",
                          fontSize: "15px",
                          fontWeight: "400",
                          height: "45px",
                          textTransform: "uppercase",
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col span={12} style={{ display: "inline-grid" }}>
                      <Button
                        onClick={() => {
                          deleteSchedule(data.id);
                        }}
                        style={{
                          backgroundColor: "#ffffff",
                          borderColor: "#ffffff",
                          color: "#F00",
                          fontSize: "15px",
                          fontWeight: "400",
                          height: "45px",
                          textTransform: "uppercase",
                        }}
                      >
                        Delete
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Modal>
            </div>
          </div>
        </Content>
      </Layout>
    );
  }
}

const styles = {
  head: {
    backgroundColor: "#F0F9FB",
    height: "56px",
  },
  textHead: {
    color: "#3EA8B8",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    fontFamily: "Gotham",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "350",
    lineHeight: "30px",
    letterSpacing: "0.15px",
  },
  bodyPage: {
    backgroundColor: "#FBFBFB",
    padding: "20px 15px 20px 15px",
  },
  boxPicture: {
    width: "96px",
    height: "96px",
    flexShrink: "0",
    borderRadius: "16px",
    border: "1px solid var(--primary-meadows-100, #B5EAEF)",
    background: "var(--text-on-dark-area",
  },
  textSchedule: {
    color: "rgba(86, 88, 93, 1)",
    fontFeatureSettings: "clig off, liga off",
    fontFamily: "Gotham",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0.4px",
  },
  textHeadmodal: {
    color: "var(--text-on-light-area, #56585D)",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    fontFamily: "Gotham",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "24px",
    letterSpacing: "0.4px",
  },
  textBodymodal: {
    color: "var(--text-on-light-area, #56585D)",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    fontFamily: "Gotham",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "24px",
    letterSpacing: "0.5px",
  },
  textInbtnA: {
    color: "#FBFBFB",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    // fontFamily: "Gotham",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "21px",
    letterSpacing: "1px",
    textTransform: "uppercase",
    with: "150px",
  },
  textStatus: {
    color: "#56585D",
    textAlign: "right",
    fontFamily: "Gotham",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
  },
  boxIcon: {
    width: "52px",
    height: "52px",
    margin: "0 5px",
  },
  borderIcon: {
    width: "42px",
    height: "42px",
    border: "1px solid var(--primary-meadows-100, #B5EAEF)",
    borderRadius: "50%",
    display: "inline-block",
    backgroundColor: "#FFF",
  },
  borderIconAction: {
    width: "42px",
    height: "42px",
    border: "1px solid var(--primary-meadows-100, #B5EAEF)",
    borderRadius: "50%",
    display: "inline-block",
    backgroundColor: "#EBF4FA",
  },
  btnCreateActton: {
    display: "flex",
    width: "360px",
    height: "48px",
    padding: "9px 0px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
    borderRadius: "8px",
    border: "1px solid #3EA8B8",
    backgroundColor: "#3EA8B8",
    color: "#FBFBFB",
    fontSize: "14px",
    fontFamily: "Gotham",
    fontWeight: "350",
    lineHeight: "21px",
  },
  btnDeleteActton: {
    display: "flex",
    width: "360px",
    height: "48px",
    padding: "9px 0px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
    borderRadius: "8px",
    border: "1px solid #FF0000",
    backgroundColor: "#FFFF",
    color: "#FF0000",
    fontSize: "14px",
    fontFamily: "Gotham",
    fontWeight: "350",
    lineHeight: "21px",
  },
  textbtnDelete: {
    color: "#FF0000",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    // fontFamily: "Gotham",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "21px",
    letterSpacing: "1px",
    textTransform: "uppercase",
  },
  textTime: {
    // fontFamily: "Gotham",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "21px",
    letterSpacing: "0px",
    color: "#919499",
  },
  textTimeChoose: {
    // fontFamily: "Gotham",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "21px",
    letterSpacing: "0px",
    color: "#56585D",
  },
};
