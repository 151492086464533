
const setFormatDay = (data) => {

    var set_day = [];
    var check_day = [];
    // 1.Mon, 2.Tue, 3.Wed, 4.Thu, 5.Fri, 6.Sat, 7.Sun

    data['schedule_day'].map((e) => {
        if (e === 1) {
            set_day.push("Mon, ");
        }
        else if (e === 2) {
            set_day.push("Tue, ");
        }
        else if (e === 3) {
            set_day.push("Wed, ");
        }
        else if (e === 4) {
            set_day.push("Thu, ");
        }
        else if (e === 5) {
            set_day.push("Fri, ");
        }
        else if (e === 6) {
            set_day.push("Sat, ");
        }
        else {
            set_day.push("Sun, ");
        }

        check_day.push(e);
    });

    // sort number
    check_day.sort();

    var count_day = set_day.length;
    var count_end = set_day[count_day-1].length;
    set_day[count_day-1] = set_day[count_day-1].substring(0,count_end-2);

    // Every weekday
    var check_mat = 0;
    for (let i = 0; i < check_day.length; i++) {
        if (check_day[i] === i+1) {
            check_mat = check_mat+1;
        }
    }

    if (check_mat === 5) {
        set_day = ["Every weekday"];
    }

    // Everyday
    check_mat = 0;
    for (let i = 0; i < check_day.length; i++) {
        if (check_day[i] === i+1) {
            check_mat = check_mat+1;
        }
    }

    if (check_mat === 7) {
        set_day = ["Everyday"];
    }

    data['set_day'] = set_day;

    // console.log(data);

    return data;

};

export default setFormatDay;