import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import HomePicture from "../../components/picture/homeicon";
import RoomTab from "../../components/home/tabroom";
import EnergyTab from "../../components/home/tabenergy";
// import apiOne from "../../api/overview/getOne";
import { getUser, getsocketstatus } from "../../stored/mydata";
import { Tabs, Row, Col } from "antd";
import { socket } from "../../socket";

export default function Home(props) {
  // const dispatch = useDispatch();

  const windowWidth = useRef(window.innerWidth);
  const widtTap = windowWidth.current / 2 - 10;
  // console.log(windowWidth);

  const myUser = useSelector(getUser);
  const socketstatus = useSelector(getsocketstatus);

  const [user, setUser] = useState({});
  // const [project, setProject] = useState({});
  // const [house, setHouse] = useState({});
  // const [colorpage, setColorpage] = useState(1);

  // const userId = useSelector((state) => state.counter.userId);

  // var projects = {};
  // var rooms = {};
  // var user_data = {};

  useEffect(() => {
    // console.log("Home");
    if (socketstatus === true) {
      socket.disconnect();
    }

    setUser(myUser);
  }, []);


  const items = [
    {
      key: "1",
      label: (
        <div
          style={{
            // color: "#56585D",
            fontFamily: "Gotham",
            width: `${widtTap}px`,
            textAlign: "center",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "350",
            lineHeight: "21px",
            letterSpacing: "0.2px",
          }}
        >
          {" "}
          Rooms
        </div>
      ),
      children: <RoomTab></RoomTab>,
    },
    {
      key: "2",
      label: (
        <div
          style={{
            // color: "#56585D",
            fontFamily: "Gotham",
            width: `${widtTap}px`,
            textAlign: "center",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "350",
            lineHeight: "21px",
            letterSpacing: "0.2px",
          }}
        >
          {" "}
          Energy
        </div>
      ),
      children: <EnergyTab></EnergyTab>,
    },
  ];

  return (
    <div className="parentDiv">
      <div className="childDiv">
        <div>
          <div style={styles.pageOne}>
            <div style={{ marginTop: "10px" }}>
              <div>
                <span style={styles.textWelcome}>Welcome, </span>
                <span style={styles.textNamePageOne}>{user.fullname}</span>
              </div>
              <div>
                <Row style={{ marginTop: "2px" }}>
                  <Col span={2} style={{ height: "15px" }}>
                    {HomePicture.home()}
                  </Col>
                  <Col span={22}>
                    <span style={styles.textHouseNumber}>
                      {user.house_number}
                    </span>
                  </Col>
                </Row>
              </div>
              <div>
                <Row>
                  <Col span={2}></Col>
                  <Col span={22}>
                    <span style={styles.textProjectName}>
                      {user.project_name_en}
                    </span>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            padding: "10px 10px 10px 10px",
            background: "#FBFBFB",
          }}
        >
          <Tabs defaultActiveKey="1" items={items} tabBarGutter={0} />
        </div>
      </div>
    </div>
  );
}

const styles = {
  pageOne: {
    height: "80px",
    backgroundColor: "#E1F6F9",
    padding: "24px",
  },
  textWelcome: {
    fontFamily: "Gotham",
    color: "#373A3F",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "16px",
    letterSpacing: "0.2px",
  },
  textNamePageOne: {
    fontFamily: "Gotham",
    color: "#3EA8B8",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "24px",
    letterSpacing: "0.4px",
  },
  textHouseNumber: {
    fontFamily: "Gotham",
    color: "#56585D",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0.4px",
    marginLeft: "2px",
  },
  textProjectName: {
    fontFamily: "Gotham",
    color: "#56585D",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "16px",
    letterSpacing: "0.2px",
    marginLeft: "2px",
  },
};
