const setDatagang = (data) => {
  var have_group = [];
  var set_data = {
    device_id: "fa-br-l",
    device_type: "freshairNew",
    device_status: "off",
    device_mode: "low",
    device_all: [],
  };

  var device_data = [];

  for (let i = 0; i < data.length; i++) {
    if (data[i].device_type === "freshair") {
      const const_h = have_group.filter((g) => g === data[i].device_group);

      if (const_h.length < 1) {
        have_group.push(data[i].device_group);

        const data_group = data.filter(
          (gr) => gr.device_group === data[i].device_group
        );

        if (data_group.length === 2) {
          data_group.sort((a, b) => a.device_gang - b.device_gang);

          data_group.map((d) => {
            if (d.device_status === "on" && d.device_gang === "1") {
              set_data["device_status"] = "on";
            }
          });

          set_data["device_all"] = data_group;

          device_data.push(set_data);
        }
      }
    } else {
      device_data.push(data[i]);
    }
  }

  // console.log(device_data);

  return device_data;
};
export default setDatagang;
