
const setFormatchart = (data) => {

    // console.log("setFormatchart", data);

    const set_data = {
        datas: [],
        data_categories: [],
    };

    if (data['emu'][0]['data'].length > 0) {
        if (data['SelectedTime'] === "HourInDay") {
            data['emu'][0]['data'].map((e, i) => {
                const number = parseInt(i) % 2;
                const time = e['hour']+":00";
                set_data['datas'].push(e['value']);
                if (number !== 0) {
                    set_data['data_categories'].push("");
                }
                else {
                     set_data['data_categories'].push(time);
                }
            });
        }

        else if (data['SelectedTime'] === "DayInMonth") {
            data['emu'][0]['data'].map((e, i) => {
                const number = parseInt(i) % 2;
                set_data['datas'].push(e['value']);
                if (number !== 0) {
                    set_data['data_categories'].push("");
                }
                else {
                    set_data['data_categories'].push(e['day']);
                }
            });
        }

        else if (data['SelectedTime'] === "MonthInYear") {
            data['emu'][0]['data'].map((e, i) => {
                const number = parseInt(i) % 2;
                set_data['datas'].push(e['value']);
                if (number !== 0) {
                    set_data['data_categories'].push("");
                }
                else {
                    set_data['data_categories'].push(e['month']);
                }
            });
        }

    }

    // console.log(set_data);

    return set_data;
};

export default setFormatchart;