import React, { useEffect, useState } from "react";
import { Row, Col, Button, Switch, Divider, Image } from "antd";
import { useNavigate } from "react-router-dom";
import apipostActiveSchedule from "../../api/schedule/postActiveSchedule";
export default function Schedule(props) {
  var navigate = useNavigate();

  const [data, setData] = useState({
    id: 2,
    schedule_time: "20:00",
    active: "false",
    schedule_name: "Movie Night",
    icon_url: "https://oneapp.obotrons.com/public/img/schedules/Bed.svg",
    status: true,
    days: "Every weekday",
    schedule_mode: "time",
  });

  // const [isshow, setIsshow] = useState(true);

  useEffect(() => {
    // effect
    if (props.data) {
      setData(props.data);
    }
    return () => {
      // cleanup
    };
  }, [props]);

  // const formatSchedule = (value) => {
  //   var count_length = value.length;
  //   var data_text = value;

  //   if (count_length > 24) {
  //     data_text = value.substring(0, 23);
  //     data_text = data_text + "...";
  //   }
  //   return data_text;
  // };

  const postActiveSchedule = (scheduleData) => {
    var data = JSON.stringify({
      schdule_id: scheduleData.id.toString(),
      cmd: scheduleData.active === "true" ? "false" : "true",
    });

    apipostActiveSchedule(data, (err, res) => {
      if (res.message === "Success") {
        navigate("/schdule");
      }
    });
  };

  return (
    <div style={{ padding: "0.5rem 16px 0rem 16px", alignItems: "flex-start" }}>
      <Row justify={"space-between"}>
        <Col
          onClick={() => {
            navigate("/editschdule/" + data.id);
          }}
        >
          <span style={{ fontSize: "1.5rem", fontWeight: 500 }}>
            {data.schedule_time !== ""
              ? data.schedule_time
              : data.schedule_mode}
          </span>
        </Col>
        <Col></Col>
      </Row>
      <Row justify={"space-between"} align={"middle"}>
        <Col
          onClick={() => {
            navigate("/editschdule/" + data.id);
          }}
          span={16}
        >
          <Row gutter={[4, 4]}>
            <Col span={6}>
              <Image preview={false} width={50} src={data.icon_url} />
            </Col>
            <Col span={18}>
              <div style={styles.textboxStatus}>{data.schedule_name}</div>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={12}>
              <Switch
                direction="vertical"
                checkedChildren="ON"
                unCheckedChildren="OFF"
                checked={data.active === "true" ? true : false}
                onClick={() => {
                  postActiveSchedule(data);
                }}
              />
            </Col>
            <Col span={12}>
              <span style={{ fontSize: "0.875rem", fontWeight: 500 }}>
                {data.active === "true" ? "Active" : "Inactive"}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        justify={"space-between"}
        align={"middle"}
        style={{ marginTop: "8px" }}
      >
        <div>{data.set_day}</div>
      </Row>
      {/* <Divider /> */}
      <hr style={styles.underlineSchedule}></hr>
    </div>
  );
}

const styles = {
  underlineSchedule: {
    backgroundColor: "#919499",
    opacity: "0.2",
    marginTop: "10px",
  },
  textboxStatus: {
    color: "#3EA8B8",
    // textAlign: "center",
    marginTop: "12px",
    fontFeatureSettings: "clig off, liga off",
    // fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22px",
    whiteSpace: "nowrap",
    width: "160px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};
