import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Modal, Button, Switch, Space, Radio, Slider } from "antd";
import ControllerPicture from "../picture/controller";

export default function Chandelier(props) {
  // const getWidth = useRef(window.innerWidth);
  // const [sizeWidth, setSizeWidth] = useState("130px");

  useEffect(() => {
    // if (getWidth.current > 430) {
    //   setSizeWidth("230px");
    // }
  }, []);

  return (
    <div>
      <div
        style={props.data["status"] === "on" ? styles.cardAction : styles.card}
      >
        <div style={{ textAlign: "end" }}>
          <Space direction="vertical">
            <Switch
              // checked={checked} onChange={setChecked}
              checked={props.data["status"] === "on" ? true : false}
              checkedChildren="ON"
              unCheckedChildren="OFF"
              // defaultChecked
            />
          </Space>
        </div>
        <div style={{ textAlign: "center" }}>
          {ControllerPicture.chandelier()}
        </div>
        <div style={styles.fontHead}>Chandelier</div>
        <div style={styles.textNumber}>{props.data["number"]}%</div>
        <div>
          <Slider
            defaultValue={parseInt(props.data["number"])}
            max={100}
            tooltip={{
              formatter: null,
            }}
          />
        </div>
      </div>
    </div>
  );
}

const styles = {
  card: {
    height: "130px",
    padding: "16px",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
    background: "#FBFBFB",
  },
  cardAction: {
    height: "130px",
    padding: "16px",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
    background: "#EBF4FA",
  },
  // card: {
  //   width: "130px",
  //   padding: "16px",
  //   // height: "156px",
  //   // fontSize: "15px",
  //   flexShrink: "0",
  //   borderRadius: "16px",
  //   border: "1px solid #B5EAEF",
  //   background: "#FBFBFB",
  // },
  // cardAction: {
  //   width: "130px",
  //   padding: "16px",
  //   // height: "156px",
  //   flexShrink: "0",
  //   borderRadius: "16px",
  //   border: "1px solid #B5EAEF",
  //   background: "#EBF4FA",
  // },
  fontHead: {
    color: "#3EA8B8",
    // fontFamily: "Gotham",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "350",
    lineHeight: "21px",
    textAlign: "center",
  },
  textNumber: {
    color: "#56585D",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    fontFamily: "Gotham",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "21px",
    letterSpacing: "0.1px",
  },
};
