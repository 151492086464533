const GetIaq = (data, cb) => {
    // console.log("GetIaq");

    fetch(`${process.env.REACT_APP_API_OBT}/api/v1/iaq?IAQ_ID=${data['IAQ_ID']}&SelectedTime=${data['SelectedTime']}`, {
        method: 'GET',
        headers: {
            'client-id': 'obt-oneapp',
        },
    })
    .then(res => res.json())
    .then(json => cb(null, json))
    .catch(err => cb(err, null))
};
export default GetIaq;