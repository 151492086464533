import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setuserloginStored,
  settokenStored,
  setuserStored,
  setcontrollerStored,
  getcontroller,
} from "../../stored/mydata";

import { socket } from "../../socket";

import { Spin } from "antd";

export default function Authentication(props) {
  const dataController = useSelector(getcontroller);

  const [uuid, setUuid] = useState("");
  const [isShow, setIsshow] = useState(false);
  const dispatch = useDispatch();

  var navigate = useNavigate();

  useEffect(() => {

    const localUser = localStorage.getItem("Obtuser");
    // setUserstr(localUser);
    const json_user = JSON.parse(localUser);

    if (
      json_user["uuid"] &&
      json_user["access_token"] &&
      json_user["unit_number"] &&
      json_user["house_number"]
    ) {
      setIsshow(true);

      //เปิดใช้งานตอนขึ้นดซิป
      if (process.env.REACT_APP_PROJECT === "production534131") {
        // localStorage.removeItem('Obtuser');
      }
      //เปิดใช้งานตอนขึ้นดซิป

      setUuid(json_user["uuid"]);

      dispatch(setuserloginStored(true));
      dispatch(setuserStored(json_user));
      dispatch(settokenStored(json_user["uuid"]));

      socket.connect();
      socket.emit(
        "join",
        `${json_user.project_code}_${json_user.house_number}`
      );

      let count = 2;
      let timerId = setInterval(() => {
        count--;
        if (count === 0) {
          clearInterval(timerId);

          navigate("/home");
        }
      }, 1000);
    }
  }, [uuid]);

  socket.on("message", (data) => {
    // console.log("ok");
    if (JSON.stringify(dataController) !== JSON.stringify(data)) {
      dispatch(setcontrollerStored(data));
    } else {
      let count = 10;
      let timerId = setInterval(() => {
        count--;
        if (count === 0) {
          clearInterval(timerId);
        }
      }, 1000);
    }
  });

  return (
    <div style={styles.example}>
      {isShow ? (
        <Spin size="large" />
      ) : (
        // <div style={styles.textNodata}>No information uuid or access_token</div>
        <div style={styles.textNodata}>
          The system is not yet available. Get started by setting up your smart
          home device.
        </div>
      )}
    </div>
  );
}

const styles = {
  example: {
    margin: "20px 0",
    marginBottom: "20px",
    padding: "30px 50px",
    textAlign: "center",
    borderRadius: "4px",
  },
  textNodata: {
    color: "#56585D",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "24px",
    letterSpacing: "0.4px",
    fontFamily: "Gotham",
  },
};
