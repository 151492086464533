import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Button,
  Tabs,
  Spin,
  Layout,
  FloatButton,
  ConfigProvider,
  Modal,
} from "antd";
import { getUser } from "../../stored/mydata";

// import { CloseOutlined } from "@ant-design/icons";
import ScenePicture from "../../components/picture/sceneicon";
import BoxItem from "../../components/schdule/boxitem";
import apigetSchedule from "../../api/schedule/getSchedule";
import SetFormatDay from "../../api/setFormat_Day";

import moment from "moment";
import dayjs from "dayjs";

const { Header, Content, Footer } = Layout;

export default function Schedule(props) {
  const myUser = useSelector(getUser);

  const windowWidth = useRef(window.innerWidth);
  const widtTap = windowWidth.current / 3 - 30;
  const widtTapBtn = windowWidth.current + 240;

  const [btnwidth, setBtnwidth] = useState(0); //380

  const [current, setCurrent] = useState("all");
  const [isshow, setIsshow] = useState(false);
  const [countScd, setCountscd] = useState(0);
  const [datas, setDatas] = useState([
    {
      id: 1,
      time: "06:00",
      active: true,
      name: "Good Morning",
      icon: "plus",
      status: true,
      days: "Everyday",
    },
  ]);
  const [datasfilter, setDatasfilter] = useState([
    {
      id: 1,
      time: "06:00",
      active: true,
      name: "Good Morning",
      icon: "plus",
      status: true,
      days: "Everyday",
    },
  ]);
  var navigate = useNavigate();
  const items = [
    {
      label: (
        <div
          style={{
            fontFamily: "Gotham",
            width: `${widtTap}px`,
            textAlign: "center",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "350",
            lineHeight: "21px",
            letterSpacing: "0.2px",
          }}
        >
          All
        </div>
      ),
      key: "all",
    },
    {
      label: (
        <div
          style={{
            fontFamily: "Gotham",
            width: `${widtTap}px`,
            textAlign: "center",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "350",
            lineHeight: "21px",
            letterSpacing: "0.2px",
          }}
        >
          Active
        </div>
      ),
      key: "active",
    },
    {
      label: (
        <div
          style={{
            fontFamily: "Gotham",
            width: `${widtTap}px`,
            textAlign: "center",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "350",
            lineHeight: "21px",
            letterSpacing: "0.2px",
          }}
        >
          Inactive
        </div>
      ),
      key: "inactive",
    },
  ];

  const [modelActive, setModelActive] = useState(false);

  const getSchedule = () => {
    var schdule_sun = [];
    var schdule_time = [];

    var data = {
      house_number: myUser.house_number,
      project_id: myUser.project_code,
      unit_number: myUser.unit_number,
    };

    apigetSchedule(data, (err, res) => {
      if (res.message === "Success") {
        var data_schedules = res.schedules;
        data_schedules.map((e) => SetFormatDay(e));
        setCountscd(res["schedules"].length);

        // sort mode and time
        schdule_sun = data_schedules.filter((e) => e.schedule_mode !== "time");
        schdule_time = data_schedules.filter((e) => e.schedule_mode === "time");

        schdule_sun.sort((a, b) => {
          const nameA = a.schedule_mode.toUpperCase();
          const nameB = b.schedule_mode.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

        schdule_time.sort((a, b) => {
          var timeA = a.schedule_time.split(":");
          var timeB = b.schedule_time.split(":");

          var dateA = moment("2022-04-01T17:00:00")
            .add(parseInt(timeA[0]), "hour")
            .add(parseInt(timeA[1]), "minute");
          var dateB = moment("2022-04-01T17:00:00")
            .add(parseInt(timeB[0]), "hour")
            .add(parseInt(timeB[1]), "minute");

          if (dateA < dateB) {
            return -1;
          }
          if (dateA > dateB) {
            return 1;
          }
          return 0;
        });
        // sort mode and time

        setDatasfilter(schdule_sun.concat(schdule_time));
        setDatas(schdule_sun.concat(schdule_time));
      }
    });
  };

  const settingWidth = () => {
    var btn_width = 0;

    if (windowWidth.current <= 400) {
      btn_width = windowWidth.current - 350;
    } else if (windowWidth.current <= 430) {
      btn_width = windowWidth.current - 370;
    } else if (windowWidth.current <= 768) {
      btn_width = windowWidth.current - 530;
    } else if (windowWidth.current <= 1024) {
      btn_width = windowWidth.current - 650;
    }
    setBtnwidth(btn_width);
    setIsshow(true);
  };
  useEffect(() => {
    getSchedule();
  }, []);

  useEffect(() => {
    // getSchedule();
    // effect
    if (current === "all") {
      setDatas(...[datasfilter]);
    } else if (current === "active") {
      const filters_d = datasfilter.filter((e) => e.active === "true");
      setDatas(...[filters_d]);
    } else {
      const filters_d = datasfilter.filter((e) => e.active === "false");
      setDatas(...[filters_d]);
    }
    settingWidth();
    return () => {
      // cleanup
    };
  }, [current]);

  const dataMax = () => {
    startModal();

    var count_time = 3;
    let timerId = setInterval(() => {
      count_time--;

      if (count_time === 0) {
        stopModal();
        clearInterval(timerId);
      }
    }, 1000);
  };

  const stopModal = () => {
    setModelActive(false);
  };
  const startModal = () => {
    setModelActive(true);
  };

  return (
    <Layout>
      <Content>
        <div>
          {!isshow ? (
            <div style={styles.reload}>
              <Spin size="large" />
            </div>
          ) : (
            <div className="parentDiv">
              <div className="childDiv">
                <div name="head" style={styles.head}>
                  <Row style={{ padding: "13px 16px" }}>
                    <Col span={4}>
                      <div
                        onClick={() => {
                          navigate("/home");
                        }}
                      >
                        {ScenePicture.backpage()}
                      </div>
                    </Col>
                    <Col span={16} style={styles.textHead}>
                      <div>Schedule</div>
                    </Col>
                    <Col span={4}></Col>
                  </Row>
                </div>
                <Tabs
                  defaultActiveKey="all"
                  centered
                  items={items}
                  onTabClick={(e) => {
                    setCurrent(e);
                  }}
                  selectedKeys={[current]}
                />
                <div style={{ height: `${widtTapBtn}px`, overflow: "scroll" }}>
                  {datas.map((d, i) => {
                    return (
                      <BoxItem
                        data={d}
                        handleUpdate={() => {
                          // TODO:
                        }}
                      />
                    );
                  })}
                </div>
                <div style={{ height: "100px" }}></div>
                <ConfigProvider
                  theme={{
                    token: {
                      colorBgElevated: "#3EA8B8",
                      colorText: "white",
                    },
                  }}
                >
                  <FloatButton
                    style={{
                      left: `${btnwidth}px`,
                      // right: `${btnwidth}px`,
                      display: "flex",
                      width: "312px",
                      height: "48px",
                      flexShrink: "0",
                      borderRadius: "8px",
                    }}
                    onClick={() => {
                      if (countScd < 5) {
                        navigate("/addschdule");
                      } else {
                        dataMax();
                      }
                    }}
                    description={
                      <div style={styles.textInbtn}>Add schedule</div>
                    }
                  />
                </ConfigProvider>
                {/* <Row justify={"center"}>
                  <Col>
                    <button
                      style={styles.btnAdd}
                      onClick={() => {
                        if (countScd < 5) {
                          navigate("/addschdule");
                        }
                      }}
                    >
                      <div style={styles.textInbtn}>Add schedule</div>
                    </button>
                  </Col>
                </Row> */}
              </div>
            </div>
          )}
        </div>

        <Modal
          centered={true}
          open={modelActive}
          footer={null}
          closable={false}
        >
          <div>
            <Row>
              <Col span={3}>
                <InfoCircleOutlined style={{ fontSize: "25px" }} />
              </Col>
              <Col span={21}>
                <div style={styles.textModelH}>Schedule Limit Reached</div>
                <div style={styles.textModelB}>
                  You've reached the maximum limit for creating schedule.
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
      </Content>
    </Layout>
  );
}

const styles = {
  head: {
    backgroundColor: "#F0F9FB",
    height: "56px",
  },
  textHead: {
    color: "#3EA8B8",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "350",
    lineHeight: "30px",
    letterSpacing: "0.15px",
  },
  btnAdd: {
    display: "flex",
    width: "312px",
    height: "48px",
    padding: "9px 0px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
    borderRadius: "8px",
    border: "1px solid #3EA8B8",
    backgroundColor: "#3EA8B8",
    color: "#FBFBFB",
    fontSize: "14px",
    fontFamily: "Gotham",
    fontWeight: "350",
    lineHeight: "21px",
  },
  textInbtn: {
    color: "#FBFBFB",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    // fontFamily: "Gotham",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "21px",
    letterSpacing: "1px",
    textTransform: "uppercase",
  },
  reload: {
    margin: "20px 0",
    marginBottom: "20px",
    padding: "30px 50px",
    textAlign: "center",
    borderRadius: "4px",
    display: "inherit",
  },
  textModelH: {
    color: "#56585D",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    fontFamily: "Gotham",
  },
  textModelB: {
    color: "#56585D",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "300",
    fontFamily: "Gotham",
  },
};
