const GetIcons = (data, cb) => {
  fetch(
    `${process.env.REACT_APP_API_OBT}/api/v1/icon?filter=${data["scenes"]}`,
    {
      method: "GET",
      headers: {
        "client-id": "obt-oneapp",
      },
    }
  )
    .then((res) => res.json())
    .then((json) => cb(null, json))
    .catch((err) => cb(err, null));
};
export default GetIcons;
