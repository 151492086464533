import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import EnergyPicture from "../picture/energy";
import EnergyChart from "./energychart";
import { Row, Col, Radio, DatePicker, Spin } from "antd";
import dayjs from "dayjs";

import { getemu } from "../../stored/mydata";
import getEmu from "../../api/home/getEmu";
import setFormatchart from "../../api/setFormat_Chart";

export default function Tabenergy(props) {
  const emus = useSelector(getemu);

  const [emu, setEmu] = useState({});
  const [valueradio, setValueRadio] = useState(1);
  const [emuvalue, setEmuvalue] = useState("");
  const [isshow, setIsshow] = useState(false);
  const [isshowdata, setIsshowdata] = useState(false);

  useEffect(() => {
    const start_emu = {
      SelectedTime: "HourInDay",
      DateTime: "now",
    };
    
    if (Object.keys(emus).length !== 0 && emus["mac"]) {
      GetEmu(start_emu);
    }
  }, []);

  const GetEmu = (data) => {
    const set_data = {
      SelectedBy: "room",
      EMU_ID: emus["mac"],
      SelectedTime: data["SelectedTime"],
      DateTime: data["DateTime"],
    };
    getEmu(set_data, (err, res) => {
      if (res["message"] === "Success") {
        setEmuvalue(parseInt(res["emu"][0]["value"]) / 1000);
        setEmu(setFormatchart(res));
        setIsshow(true);
        setIsshowdata(true);
      }
    });
  };

  const onChangeRadio = (e) => {
    setValueRadio(e.target.value);
  };
  const onChangeDay = (date, dateString) => {
    if (date != null) {
      setIsshow(false);

      var set_day = "";
      var set_month = "";

      const set_data = {
        SelectedTime: "HourInDay",
        DateTime: "",
      };

      if (date["$D"] < 10) {
        set_day = "0" + date["$D"];
      } else {
        set_day = date["$D"];
      }

      if (date["$M"] + 1 < 10) {
        set_month = "0" + (date["$M"] + 1);
      } else if (date["$M"] + 1 >= 10) {
        set_month = date["$M"] + 1;
      }

      set_data["DateTime"] = set_day + "/" + set_month + "/" + date["$y"];
      GetEmu(set_data);
    }
  };
  const onChangeMonth = (date, dateString) => {
    if (date != null) {
      setIsshow(false);

      var set_month = "";

      const set_data = {
        SelectedTime: "DayInMonth",
        DateTime: "",
      };

      if (date["$M"] + 1 < 10) {
        set_month = "0" + (date["$M"] + 1);
      } else if (date["$M"] + 1 >= 10) {
        set_month = date["$M"] + 1;
      }

      set_data["DateTime"] = set_month + "/" + date["$y"];
      GetEmu(set_data);
    }
  };
  const onChangeYear = (date, dateString) => {
    if (date != null) {
      setIsshow(false);

      const set_data = {
        SelectedTime: "MonthInYear",
        DateTime: "",
      };

      set_data["DateTime"] = date["$y"];
      GetEmu(set_data);
    }
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    // ย้อนหลังได้ 1 ปี
    return current && current < dayjs().add(-1, "year");
  };

  return (
    <div>
      {isshowdata ? (
        <div style={styles.card}>
          <div style={{ paddingLeft: "6px", paddingRight: "6px" }}>
            <div id="cardEmu" style={{ padding: "16px" }}>
              <Row gutter={[8, 8]}>
                <Col span={2}> {EnergyPicture.energy()}</Col>
                <Col span={22}>
                  <Row>
                    <Col>
                      <span style={styles.haedText}>
                        Energy Measurement Unit
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span style={styles.haedTextsub}>
                        (Data displayed on EMU is approximate)
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div style={{ marginTop: "16px", marginBottom: "16px" }}>
                <Radio.Group
                  onChange={onChangeRadio}
                  value={valueradio}
                  style={{ width: "100%" }}
                >
                  <Row style={{ height: "35px" }}>
                    <Col span={12} style={{ alignItems: "center" }}>
                      <div style={{ marginTop: "2px" }}>
                        <Radio value={1}>
                          {valueradio !== 1 ? (
                            <span style={styles.radioText}>Day</span>
                          ) : null}
                          {valueradio === 1 ? (
                            <span style={styles.radioTextChoose}>Day</span>
                          ) : null}
                        </Radio>
                      </div>
                    </Col>
                    <Col span={12} style={{ textAlign: "end" }}>
                      <div>
                        {valueradio === 1 ? (
                          <div>
                            <DatePicker
                              style={{ width: "100px" }}
                              onChange={onChangeDay}
                              picker="day"
                              format="dd DD"
                              disabledDate={disabledDate}
                            />
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ height: "35px" }}>
                    <Col span={12} style={{ alignItems: "center" }}>
                      <div style={{ marginTop: "2px" }}>
                        <Radio value={2}>
                          {valueradio !== 2 ? (
                            <span style={styles.radioText}>Month</span>
                          ) : null}
                          {valueradio === 2 ? (
                            <span style={styles.radioTextChoose}>Month</span>
                          ) : null}
                        </Radio>
                      </div>
                    </Col>
                    <Col span={12} style={{ textAlign: "end" }}>
                      <div>
                        {valueradio === 2 ? (
                          <div>
                            <DatePicker
                              style={{ width: "140px" }}
                              onChange={onChangeMonth}
                              picker="month"
                              format="MMMM YYYY"
                              disabledDate={disabledDate}
                            />
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ height: "35px" }}>
                    <Col span={12} style={{ alignItems: "center" }}>
                      <div style={{ marginTop: "2px" }}>
                        <Radio value={3}>
                          {valueradio !== 3 ? (
                            <span style={styles.radioText}>Year</span>
                          ) : null}
                          {valueradio === 3 ? (
                            <span style={styles.radioTextChoose}>Year</span>
                          ) : null}
                        </Radio>
                      </div>
                    </Col>
                    <Col span={12} style={{ textAlign: "end" }}>
                      <div>
                        {valueradio === 3 ? (
                          <div>
                            <DatePicker
                              style={{ width: "90px" }}
                              onChange={onChangeYear}
                              picker="year"
                              format="YYYY"
                              disabledDate={disabledDate}
                            />
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </Radio.Group>
              </div>
              <div style={{ marginBottom: "16px" }}>
                <div style={styles.borderTotalEmu}>
                  <Row>
                    <Col span={12} style={styles.textTotal}>
                      Total:
                    </Col>
                    <Col span={12} style={styles.textValue}>
                      {emuvalue} kWh
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            {isshow === false ? (
              <div style={styles.reload}>
                <Spin size="large" />
              </div>
            ) : (
              <div id="cardChart" style={{ padding: "16px" }}>
                <div>
                  <div style={styles.headChart}>kWh</div>
                  <div>
                    <EnergyChart dataemu={emu} />
                  </div>
                  <div style={styles.typeChart}>Time</div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div style={styles.reload}>
          <div style={styles.textSection}>
            The system is not yet available. Get started by setting up your
            smart home device.
          </div>
        </div>
      )}
    </div>
  );
}

const styles = {
  card: {
    // fontFamily: "Gotham",
    // height: "620px",
    // backgroundColor: '#FFFFFF',
  },
  haedText: {
    color: "#3EA8B8",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
  },
  haedTextsub: {
    color: "#3EA8B8",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "21px",
  },
  radioText: {
    color: "#919499",
    // textAlign: "center",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
  },
  radioTextChoose: {
    color: "#56585D",
    // textAlign: "center",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
  },
  textTotal: {
    color: "#3EA8B8",
    textAlign: "start",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
  },
  textValue: {
    color: "#3EA8B8",
    textAlign: "end",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
  },
  borderTotalEmu: {
    // display: "flex",
    // width: "296px",
    padding: "8px 16px",
    justifyContent: "space-between",
    alignItems: "flex-start",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
  },
  headChart: {
    color: "#919499",
    textAlign: "center",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
  },
  typeChart: {
    color: "#919499",
    textAlign: "center",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
  },
  reload: {
    margin: "20px 0",
    marginBottom: "20px",
    padding: "30px 50px",
    textAlign: "center",
    borderRadius: "4px",
    display: "inherit",
  },
  textSection: {
    color: "#56585D",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "24px" /* 150% */,
    letterSpacing: "0.4px",
    fontFamily: "Gotham",
  },
};
