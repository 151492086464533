const GetSceneId = (data, cb) => {
    fetch(
        `${process.env.REACT_APP_API_OBT}/api/v1/scene/device?scene_id=${data['scene_id']}&house_number=${data['house_number']}&project_id=${data['project_id']}&unit_number=${data['unit_number']}`,
        {
          method: "GET",
          headers: {
            "client-id": "obt-oneapp",
          },
        }
      )
        .then((res) => res.json())
        .then((json) => cb(null, json))
        .catch((err) => cb(err, null));
};
export default GetSceneId;