import { Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import ControllerPicture from "../../components/picture/controller";
import { useSelector } from "react-redux";
import { getisreload } from "../../stored/mydata";

export default function Curtain(props) {
  // const reload = useSelector(getisreload);
  const [data, setData] = useState({
    device_id: "ct-a",
    device_model: "wulian",
    device_name: "curtain 1",
    device_status: "on",
    device_type: "curtains",
    id: 0,
    mac: "50294D2257EE",
    select_scene: "false",
  });

  // const [data, setData] = useState({
  //   select: "false",
  //   id: 8,
  //   mac: "50294D2257EE",
  //   device_model: "wulian",
  //   device_name: "curtain 1",
  //   device_status: "on",
  //   device_id: "ct-a",
  // });

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
    return () => {
      // setData({})
    };
  }, [props.data]);

  useEffect(() => {
    props.getData(data);
    return () => {};
  }, [data]);

  return (
    <div>
      {data.select_scene === "false" ? (
        <div style={styles.card}>
          <div>
            <Row gutter={[16, 16]}>
              <Col span={7}>
                <div
                  style={styles.fontHead}
                  onClick={() => {
                    setData({ ...data, select_scene: "true" });
                  }}
                >
                  Curtain
                </div>
                <div
                  style={{ textAlign: "center" }}
                  onClick={() => {
                    setData({ ...data, select_scene: "true" });
                  }}
                >
                  {ControllerPicture.curtain()}
                </div>
              </Col>
              <Col span={17}>
                <Row gutter={[8, 8]}>
                  <Col span={8}>
                    <div style={styles.textInbtn}>
                      <div style={styles.btnControll}>
                        <Row gutter={[8, 8]}>
                          <Col>{ControllerPicture.less()}</Col>
                          <Col>{ControllerPicture.more_than()}</Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div style={styles.textInbtn}>
                      <div style={styles.btnControll}>
                        <Row gutter={[8, 8]}>
                          <Col>{ControllerPicture.pause()}</Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div style={styles.textInbtn}>
                      <div style={styles.btnControll}>
                        <Row gutter={[8, 8]}>
                          <Col>{ControllerPicture.more_than()}</Col>
                          <Col>{ControllerPicture.less()}</Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      ) : null}
      {data.select_scene === "true" ? (
        <div style={styles.cardAction}>
          <div>
            <Row gutter={[16, 16]}>
              <Col span={7}>
                <div
                  style={styles.fontHead}
                  onClick={() => {
                    setData({ ...data, select_scene: "false" });
                  }}
                >
                  Curtain
                </div>
                <div
                  style={{ textAlign: "center" }}
                  onClick={() => {
                    setData({ ...data, select_scene: "false" });
                  }}
                >
                  {ControllerPicture.curtain()}
                </div>
              </Col>
              <Col span={17}>
                <Row gutter={[8, 8]}>
                  <Col span={8}>
                    <div style={styles.textInbtn}>
                      <div
                        style={
                          data["device_status"] === "on"
                            ? styles.btnControllerChoose
                            : styles.btnControll
                        }
                        onClick={() => {
                          setData({ ...data, device_status: "on" });
                        }}
                      >
                        <Row gutter={[8, 8]}>
                          <Col>{ControllerPicture.less()}</Col>
                          <Col>{ControllerPicture.more_than()}</Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div style={styles.textInbtn}>
                      <div
                        style={
                          data["device_status"] === "stop"
                            ? styles.btnControllerChoose
                            : styles.btnControll
                        }
                        onClick={() => {
                          setData({ ...data, device_status: "stop" });
                        }}
                      >
                        <Row gutter={[8, 8]}>
                          <Col>{ControllerPicture.pause()}</Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div style={styles.textInbtn}>
                      <div
                        style={
                          data["device_status"] === "off"
                            ? styles.btnControllerChoose
                            : styles.btnControll
                        }
                        onClick={() => {
                          setData({ ...data, device_status: "off" });
                        }}
                      >
                        <Row gutter={[8, 8]}>
                          <Col>{ControllerPicture.more_than()}</Col>
                          <Col>{ControllerPicture.less()}</Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      ) : null}
    </div>
  );
}

const styles = {
  card: {
    padding: "16px",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
    // width: "318px",
    // height: "103px",
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "flex-start",
    // gap: "12px",
  },
  cardAction: {
    padding: "16px",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
    background: "#EBF4FA",
    // width: "318px",
    // height: "103px",
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "flex-start",
    // gap: "12px",
  },
  fontHead: {
    fontFamily: "Gotham",
    color: "#3EA8B8",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
    textAlign: "center",
  },
  btnControll: {
    display: "flex",
    width: "40px",
    height: "40px",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
    borderRadius: "14px",
    border: "1px solid #B5EAEF",
    background: "#FFF",
    boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.10)",
  },
  btnControllerChoose: {
    display: "flex",
    width: "40px",
    height: "40px",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
    borderRadius: "14px",
    border: "1px solid #B5EAEF",
    background: "#EBF4FA",
    // background: "#FFF",
    boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.10)",
  },
  textInbtn: {
    height: "100px",
    alignItems: "center",
    display: "flex",
    // padding: "0px 20px",
  },
};
