const PostActiveSchedule = (data, cb) => {
  fetch(`${process.env.REACT_APP_API_OBT}/api/v1/schedule/active`, {
    method: "POST",
    headers: {
      "client-id": "obt-oneapp",
      "Content-Type": "application/json",
    },
    body: data,
  })
    .then((res) => res.json())
    .then((json) => cb(null, json))
    .catch((err) => cb(err, null));
};
export default PostActiveSchedule;
