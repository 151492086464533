const GetTimepir = (data, cb) => {
  fetch(
    `${process.env.REACT_APP_API_OBT}/api/v1/pir/time?mac=${data["mac"]}&device_id=${data["device_id"]}&device_model=${data["device_model"]}&device_type=${data["device_type"]}`,
    {
      method: "GET",
      headers: {
        "client-id": "obt-oneapp",
      },
    }
  )
    .then((res) => res.json())
    .then((json) => cb(null, json))
    .catch((err) => cb(err, null));
};
export default GetTimepir;
