import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Input, Image, Spin } from "antd";

import Ervdevice from "../../components/controllerscene/erv";
import Doasdevice from "../../components/controllerscene/doas";
import Airdevice from "../../components/controllerscene/air";
import Lampdevice from "../../components/controllerscene/lamp";
import Chandelierdevice from "../../components/controllerscene/chandelier";
import Curtaindevice from "../../components/controllerscene/curtain";
import AirCassettedevice from "../../components/controllerscene/airCassette";
import AirCassette2device from "../../components/controllerscene/airCassette2";
import AirCoolthingdevice from "../../components/controllerscene/airCoolthing";
import AirAllInOne from "../../components/controllerscene/airAllInOne";
import AirCondition from "../../components/controllerscene/airCondition";
import LightingDim from "../../components/controllerscene/lightingDim";

import {
  getroomScene,
  getsceneName,
  getisreload,
  setisreloadStored,
} from "../../stored/mydata";

export default function SceneAddDevices(props) {
  const [data, setData] = useState({
    id: 1,
    room_name: "Kitchen",
    icon: "",
    device: [],
  });
  const [btnSubmit, setBtnsubmit] = useState(false);
  const [count_select, setCountselect] = useState(0);
  const [isShow, setIsshow] = useState(false);

  const Set_data = (edit) => {
    var tmp = data.device.map((e) => {
      if (e.id === edit.id) {
        return edit;
      } else {
        return e;
      }
    });
    setData({ ...data, device: tmp });
    // checkAction(edit.select_scene);
  };

  useEffect(() => {
    if (props.data) {
      // console.log(props.data);

      let count = 0;
      props.data.device.map((e) => {
        if (e.select_scene === "true") {
          count++;
        }
      });
      // console.log("count", count);
      setCountselect(count);
      setData(props.data);
      setBtnsubmit(true);
      setIsshow(true);
    }
    return () => {
      // cleanup
    };
  }, [props]);

  const checkAction = (data) => {
    let num = count_select;
    if (data === "true") {
      num++;
    } else {
      num--;
    }

    if (num > 0) {
      setBtnsubmit(true);
    } else {
      setBtnsubmit(false);
    }
  };

  return (
    <div>
      {isShow ? (
        <div className="parentDiv">
          <div className="childDiv">
            <div>
              <div name="head" style={styles.head}>
                <Row style={{ padding: "13px 16px" }}>
                  <Col span={4}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      onClick={() => {
                        props.handleBack();
                      }}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.8086 6.14632L4.46391 11.4873C4.45193 11.4972 4.4403 11.5078 4.42907 11.519L4.14632 11.802C3.95113 11.9973 3.95124 12.3139 4.14657 12.5091L9.8054 18.164C10.0007 18.3592 10.3173 18.3591 10.5125 18.1637L10.7952 17.8808C10.9904 17.6855 10.9903 17.3689 10.795 17.1737L6.4655 12.8472H19.7867C19.9082 12.8472 20.0067 12.7487 20.0067 12.6272V11.6672C20.0067 11.5457 19.9082 11.4472 19.7867 11.4472H6.48461L10.7982 7.13662C10.9935 6.94142 10.9936 6.62484 10.7984 6.42951L10.5157 6.14657C10.3205 5.95124 10.0039 5.95113 9.8086 6.14632Z"
                        fill="#56585D"
                      />
                    </svg>
                  </Col>
                  <Col span={16} style={styles.textHead}>
                    <div>{data.room_name}</div>
                  </Col>
                  <Col span={4}></Col>
                </Row>
              </div>
              <div
                style={{
                  padding: "24px 16px 0px 16px",
                  background: "#FBFBFB",
                  fontFamily: "Gotham",
                  fontSize: "14px",
                  color: "#56585D",
                  fontWeight: "300",
                }}
              >
                If a device is not selected, it won't be controlled by the
                scene.
              </div>
              <div style={styles.bodyPage}>
                <Row gutter={[16, 16]}>
                  {data.device.map((e, i) => {
                    if (e.device_type === "erv") {
                      return (
                        <Col span={24} key={i + "_device"}>
                          <Ervdevice
                            data={e}
                            getData={(b) => {
                              Set_data(b);
                            }}
                          />
                        </Col>
                      );
                    } else if (e.device_type === "doas") {
                      return (
                        <Col span={24} key={i + "_device"}>
                          <Doasdevice data={e} />
                        </Col>
                      );
                    } else if (e.device_type === "ac") {
                      if (e.device_model === "cassette") {
                        return (
                          <Col span={24} key={i + "_device"}>
                            <AirCassettedevice
                              data={e}
                              getData={(b) => {
                                Set_data(b);
                              }}
                            />
                          </Col>
                        );
                      } else if (e.device_model === "cassette2") {
                        return (
                          <Col span={24} key={i + "_device"}>
                            <AirCassette2device
                              data={e}
                              getData={(b) => {
                                Set_data(b);
                              }}
                            />
                          </Col>
                        );
                      } else if (e.device_model === "daikin-coolthing") {
                        return (
                          <Col span={24} key={i + "_device"}>
                            <AirCoolthingdevice
                              data={e}
                              getData={(b) => {
                                Set_data(b);
                              }}
                            />
                          </Col>
                        );
                      } else if (e.device_model === "AirController-AllInOne") {
                        // air controller all in one
                        return (
                          <Col span={24} key={i + "_device"}>
                            <AirAllInOne
                              data={e}
                              getData={(b) => {
                                Set_data(b);
                              }}
                            />
                          </Col>
                        );
                      } else if (e.device_model === "AirController-Condition") {
                        // air controller condition
                        return (
                          <Col span={24} key={i + "_device"}>
                            <AirCondition
                              data={e}
                              getData={(b) => {
                                Set_data(b);
                              }}
                            />
                          </Col>
                        );
                      }
                    } else if (e.device_type === "lights") {
                      if (e.device_model === "wulian") {
                        return (
                          <Col span={12} key={i + "_device"}>
                            <Lampdevice
                              data={e}
                              getData={(b) => {
                                Set_data(b);
                              }}
                            />
                          </Col>
                        );
                      } else if (e.device_model === "dim") {
                        // lighting controller dim
                        return (
                          <Col span={12} key={i + "_device"}>
                            <LightingDim
                              data={e}
                              getData={(b) => {
                                Set_data(b);
                              }}
                            />
                          </Col>
                        );
                      }
                    } else if (e.device_type === "chandelier") {
                      return (
                        <Col span={12} key={i + "_device"}>
                          <Chandelierdevice data={e} />
                        </Col>
                      );
                    } else if (e.device_type === "curtains") {
                      return (
                        <Col span={24} key={i + "_device"}>
                          <Curtaindevice
                            data={e}
                            getData={(b) => {
                              Set_data(b);
                            }}
                          />
                        </Col>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Row>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  // marginTop: "10px",
                  marginBottom: "55px",
                }}
              >
                <div>
                  {btnSubmit === true ? (
                    <button
                      style={styles.btnCreateActton}
                      onClick={() => {
                        props.handleUpdated(data);
                      }}
                    >
                      <div style={styles.textInbtnA}>save changes</div>
                    </button>
                  ) : (
                    <button style={styles.btnCreate}>
                      <div style={styles.textInbtn}>save changes</div>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={styles.reload}>
          <Spin size="large" />
        </div>
      )}
    </div>
  );
}

const styles = {
  head: {
    backgroundColor: "#F0F9FB",
    height: "56px",
  },
  textHead: {
    color: "#3EA8B8",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    fontFamily: "Gotham",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "350",
    lineHeight: "30px",
    letterSpacing: "0.15px",
  },
  bodyPage: {
    padding: "20px 16px 20px 16px",
  },
  btnCreate: {
    display: "flex",
    width: "312px",
    height: "48px",
    padding: "9px 0px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
    borderRadius: "8px",
    border: "1px solid #919499",
    backgroundColor: "#919499",
    color: "rgba(251, 251, 251, 1)",
    fontSize: "14px",
    fontFamily: "Gotham",
    fontWeight: "350",
    lineHeight: "21px",
  },
  btnCreateActton: {
    display: "flex",
    width: "312px",
    height: "48px",
    padding: "9px 0px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
    borderRadius: "8px",
    border: "1px solid #3EA8B8",
    backgroundColor: "#3EA8B8",
    color: "#FBFBFB",
    fontSize: "14px",
    fontFamily: "Gotham",
    fontWeight: "350",
    lineHeight: "21px",
  },
  textInbtn: {
    color: "#FBFBFB",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    // fontFamily: "Gotham",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "21px",
    letterSpacing: "1px",
    textTransform: "uppercase",
  },
  textInbtnA: {
    color: "#FBFBFB",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    // fontFamily: "Gotham",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "21px",
    letterSpacing: "1px",
    textTransform: "uppercase",
  },
  reload: {
    margin: "20px 0",
    marginBottom: "20px",
    padding: "30px 50px",
    textAlign: "center",
    borderRadius: "4px",
    display: "inherit",
  },
};
