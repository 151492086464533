import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HomePicture from "../../components/picture/homeicon";
import { Row, Col, Modal } from "antd";

export default function CardIaq(props) {
  var navigate = useNavigate();

  const [modaliaq, setModaliaq] = useState(false);
  const [dataIaq, setDataIaq] = useState({});
  //   const [iaq, setIaq] = useState({});

  const stopModaliaq = () => {
    setModaliaq(false);
  };

  const setDataiaq = (data) => {
    setDataIaq(data);
  };

  return (
    <div>
      {props["page"] === "history" ? (
        <div style={styles.card}>
          <div>
            <div>
              <Row gutter={[8, 8]}>
                <Col span={9}>
                  <div
                    style={{
                      fontSize: "16px",
                      color: `${props["data"].color}`,
                      fontStyle: "normal",
                      fontWeight: "350",
                      lineHeight: "normal",
                      fontFamily: "Gotham",
                    }}
                  >
                    {props["data"].text}
                  </div>
                  <div style={{ alignItems: "center", marginTop: "8px" }}>
                    <span style={{ marginRight: "4px" }}>
                      {HomePicture.cloud()}
                    </span>
                    <span style={styles.textNameInCard}>Humidity</span>
                  </div>
                  <div style={{ alignItems: "center" }}>
                    <span style={styles.textValueInCard16}>
                      {props["data"].humidity} %
                    </span>
                  </div>
                  <div style={styles.textTemp}>
                    {props["data"].temprtature}°C{" "}
                  </div>
                </Col>
                <Col span={15}>
                  <div
                    style={{ display: "flex", justifyContent: "end" }}
                    onClick={() => {
                      setModaliaq(true);
                      setDataiaq(props["data"]);
                    }}
                  >
                    {HomePicture.details()}
                  </div>
                  <Row
                    gutter={[8, 8]}
                    style={{
                      marginTop: "8px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col span={11}>
                      <Row>
                        <Col span={12}>
                          <div style={styles.textNameInCard}>PM2.5</div>
                          <div style={styles.textUnitInCard}>(µg/m3)</div>
                        </Col>
                        <Col span={12} style={styles.textValueCol}>
                          <span style={styles.textValueInCard}>
                            {props["data"].pm25}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row>
                        <Col span={12}>
                          <span style={styles.textNameInCard}>CO2</span>
                          <div style={styles.textUnitInCard}>(ppm)</div>
                        </Col>
                        <Col span={12} style={styles.textValueCol}>
                          <span style={styles.textValueInCard}>
                            {props["data"].co2}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row
                    gutter={[8, 8]}
                    style={{
                      marginTop: "8px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col span={11}>
                      <Row>
                        <Col span={12}>
                          <span style={styles.textNameInCard}>PM10</span>
                          <div style={styles.textUnitInCard}>(µg/m3)</div>
                        </Col>
                        <Col span={12} style={styles.textValueCol}>
                          <span style={styles.textValueInCard}>
                            {props["data"].pm10}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row>
                        <Col span={12}>
                          <span style={styles.textNameInCard}>TVOCC</span>
                          <div style={styles.textUnitInCard}>(mg/m3)</div>
                        </Col>
                        <Col span={12} style={styles.textValueCol}>
                          <span style={styles.textValueInCard}>
                            {props["data"].tvoc}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div style={styles.textLast}>
                    Last updated at {props["data"].last_time}{" "}
                    <span style={{ marginLeft: "8px" }}>
                      {HomePicture.reload()}
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      ) : null}
      {props["page"] === "home" ? (
        <div style={styles.cardHome}>
          <div>
            <div>
              <Row gutter={[8, 8]}>
                <Col
                  span={9}
                  onClick={() => {
                    navigate("/history");
                  }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      color: `${props["data"].color}`,
                      fontStyle: "normal",
                      fontWeight: "350",
                      lineHeight: "normal",
                      fontFamily: "Gotham",
                      // textAlign: "center",
                    }}
                  >
                    {props["data"].text}
                  </div>
                  <div style={{ alignItems: "center", marginTop: "8px" }}>
                    <span style={{ marginRight: "4px" }}>
                      {HomePicture.cloud()}
                    </span>
                    <span style={styles.textNameInCard}>Humidity</span>
                  </div>
                  <div style={{ alignItems: "center" }}>
                    <span style={styles.textValueInCard16}>
                      {props["data"].humidity} %
                    </span>
                  </div>
                  <div style={styles.textTemp}>
                    {props["data"].temprtature}°C{" "}
                  </div>
                </Col>
                <Col span={15}>
                  <div
                    style={{ display: "flex", justifyContent: "end" }}
                    onClick={() => {
                      setModaliaq(true);
                      setDataiaq(props["data"]);
                    }}
                  >
                    {HomePicture.details()}
                  </div>
                  <Row
                    gutter={[8, 8]}
                    style={{
                      marginTop: "8px",
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      navigate("/history");
                    }}
                  >
                    <Col span={11}>
                      <Row>
                        <Col span={12}>
                          <div style={styles.textNameInCard}>PM2.5</div>
                          <div style={styles.textUnitInCard}>(µg/m3)</div>
                        </Col>
                        <Col span={12} style={styles.textValueCol}>
                          <span style={styles.textValueInCard}>
                            {props["data"].pm25}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row>
                        <Col span={12}>
                          <span style={styles.textNameInCard}>CO2</span>
                          <div style={styles.textUnitInCard}>(ppm)</div>
                        </Col>
                        <Col span={12} style={styles.textValueCol}>
                          <span style={styles.textValueInCard}>
                            {props["data"].co2}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row
                    gutter={[8, 8]}
                    style={{
                      marginTop: "8px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col span={11}>
                      <Row>
                        <Col span={12}>
                          <span style={styles.textNameInCard}>PM10</span>
                          <div style={styles.textUnitInCard}>(µg/m3)</div>
                        </Col>
                        <Col span={12} style={styles.textValueCol}>
                          <span style={styles.textValueInCard}>
                            {props["data"].pm10}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row>
                        <Col span={12}>
                          <span style={styles.textNameInCard}>TVOC</span>
                          <div style={styles.textUnitInCard}>(mg/m3)</div>
                        </Col>
                        <Col span={12} style={styles.textValueCol}>
                          <span style={styles.textValueInCard}>
                            {props["data"].tvoc}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div
                    style={styles.textLast}
                    onClick={() => {
                      navigate("/history");
                    }}
                  >
                    Last updated at {props["data"].last_time}{" "}
                    <span style={{ marginLeft: "8px" }}>
                      {HomePicture.reload()}
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      ) : null}
      <Modal
        centered={true}
        open={modaliaq}
        onCancel={stopModaliaq}
        footer={null}
        closable={false}
        width={314}
        style={{ padding: "24px 25px" }}
      >
        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
          {/* <div style={{ padding: "24px 25px" }}> */}
          <div style={{ display: "grid" }}>
            <Row style={{ display: "flex", justifyContent: "center" }}>
              <Col style={styles.textHeadModal}>Health Impact</Col>
              <Col style={{ marginLeft: "2%", marginTop: "2%" }}>
                {HomePicture.details()}
              </Col>
            </Row>
          </div>
          <div style={styles.textTop}>
            Parameter Maximum Value
            <ul
              style={{ marginTop: 0, marginBottom: "0", paddingLeft: "25px" }}
            >
              <li>Temperature {dataIaq.temprtature}°C</li>
              <li>Humidity {dataIaq.humidity} %</li>
              <li>PM2.5 {dataIaq.pm25} μg/m³</li>
              <li>PM10 {dataIaq.pm10} μg/m³</li>
              <li>CO2 {dataIaq.co2} ppm</li>
              <li>TVOC {dataIaq.tvoc} mg/m³</li>
            </ul>
          </div>
          <div style={styles.textLower}>
            Note: Exceeding the mentioned thresholds indicates a higher health
            risk. Take steps to improve indoor air quality.
          </div>
        </div>
        <div
          style={styles.btnInModal}
          onClick={() => {
            stopModaliaq();
          }}
        >
          <span style={styles.textInbtn}>CLOSE</span>
        </div>
      </Modal>
    </div>
  );
}

const styles = {
  card: {
    height: "116px",
    // height: "146px",
    justifyContent: "space-between",
    // alignItems: "center",
    flexShrink: 0,
    borderRadius: "16px",
    background: "#FFF",
    fontSize: "16px",
    padding: "16px",
    marginBottom: "16px",
    fontFamily: "Gotham",
  },
  cardHome: {
    height: "126px",
    justifyContent: "space-between",
    flexShrink: 0,
    borderRadius: "16px",
    background: "#FFF",
    fontSize: "16px",
    padding: "16px",
    marginBottom: "16px",
    fontFamily: "Gotham",
  },
  textLocation: {
    color: "#56585D",
    // textAlign: "center",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "325",
    lineHeight: "21px",
    marginTop: "8px",
    fontFamily: "Gotham",
  },
  textTemp: {
    color: "#56585D",
    // textAlign: "center",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: "325",
    lineHeight: "32px",
    letterSpacing: "0.15px",
    marginTop: "8px",
    fontFamily: "Gotham",
  },
  textNameInCard: {
    color: "#56585D",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "325",
    lineHeight: "normal",
    fontFamily: "Gotham",
  },
  textValueInCard16: {
    color: "#56585D",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "325",
    lineHeight: "normal",
    marginLeft: "16px",
    fontFamily: "Gotham",
  },
  textValueInCard: {
    color: "#56585D",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "325",
    lineHeight: "normal",
    marginLeft: "6px",
    fontFamily: "Gotham",
  },
  textValueCol: {
    textAlign: "end",
  },
  textUnitInCard: {
    color: "#919499",
    fontFamily: "Inter",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  textLast: {
    color: "#919499",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    marginTop: "12px",
    display: "flex",
    justifyContent: "end",
  },
  textHeadModal: {
    color: "#3EA8B8",
    textAlign: "center",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0.4px",
    fontFamily: "Gotham",
  },
  textTop: {
    color: "#56585D",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "350",
    lineHeight: "24px",
    letterSpacing: "0.5px",
    fontFamily: "Gotham",
  },
  textLower: {
    color: "#56585D",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "350",
    fontFamily: "Gotham",
  },
  btnInModal: {
    borderRadius: "8px",
    background: "#3EA8B8",
    display: "flex",
    // width: "264px",
    // height: "48px",
    padding: "9px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    flexShrink: "0",
    marginTop: "8px",
    fontFamily: "Gotham",
  },
  textInbtn: {
    color: "#FBFBFB",
    textAlign: "center",
    /* Button */
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "21px" /* 150% */,
    letterSpacing: "1px",
    textTransform: "uppercase",
    fontFamily: "Gotham",
  },
};
