import { Row, Col, Modal, Button, Switch, Space, Radio, Slider } from "antd";
import ControllerPicture from "../picture/controller";

export default function Erv(props) {
  const marks = {
    0: (
      <>
        <div style={styles.textModeFan}>Low</div>
      </>
    ),
    50: (
      <>
        <div style={styles.textModeFan}>Mid</div>
      </>
    ),
    100: (
      <>
        <div style={styles.textModeFan}>High</div>
      </>
    ),
  };

  return (
    <div>
      <div style={styles.card}>
        <Row>
          <Col span={3} style={{ width: "200px" }}>
            {ControllerPicture.doas()}
          </Col>
          <Col span={21}>
            <div style={styles.fontInpage}>Dedicated Outdoor Air System</div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={4} style={styles.textMode}>
            Mode:
          </Col>
          <Col span={20}>
            <Radio.Group
              onChange={(e) => {
                // console.log(e.target.value);
              }}
              // value={1}
              value={props.data["mode"] === "auto" ? 1 : 2}
            >
              <Row>
                <Col span={12}>
                  <Radio
                    value={1}
                    style={
                      props.data["mode"] === "auto"
                        ? styles.fontNumber_choose
                        : styles.fontNumber
                    }
                  >
                    Auto
                  </Radio>
                </Col>
                <Col span={12}>
                  <Radio
                    value={2}
                    style={
                      props.data["mode"] === "auto"
                        ? styles.fontNumber
                        : styles.fontNumber_choose
                    }
                  >
                    Manual
                  </Radio>
                </Col>
              </Row>
            </Radio.Group>
          </Col>
        </Row>
        <div>
          <Row>
            <Col span={8} style={{ width: "250px", textAlign: "end" }}>
              {ControllerPicture.minus()}
            </Col>
            <Col span={8} style={styles.fontNumberTemp}>
              {props.data["temp"]}°
            </Col>
            <Col span={8} style={{ width: "250px" }}>
              {ControllerPicture.plus()}
            </Col>
          </Row>
        </div>
        <div style={styles.cardInerv}>
          <Row>
            <Col span={7}>
              <Row>
                <Col span={12} style={{ width: "250px" }}>
                  {ControllerPicture.wind()}
                </Col>
                <Col span={12}>
                  <div style={styles.fontInpage}>Fan</div>
                </Col>
              </Row>
            </Col>
            <Col span={17}>
              <Slider
                marks={marks}
                defaultValue={50}
                step={50}
                //   activeDotStyle={{
                //     backgroundColor: "rgba(62, 168, 184, 1)"
                //   }}
                tooltip={{
                  formatter: null,
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

const styles = {
  head: {
    backgroundColor: "#F0F9FB",
    height: "108px",
  },
  card: {
    display: "flex",
    padding: "16px",
    flexDirection: "column",
    // alignItems: "flex-start",
    gap: "12px",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
  },
  cardInerv: {
    // width: "90%",
    // width: "100%",
    // height: "62px",
    flexShrink: "0",
    borderRadius: "16px",
    border: "1px solid var(--primary-meadows-100, #B5EAEF)",
    background: "var(--text-on-dark-area, #FBFBFB)",
    padding: "15px 15px 0 15px",
  },
  fontInpage: {
    color: "#3EA8B8",
    // textAlign: "center",
    paddingTop: "2px",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
  },
  fontNumberTemp: {
    color: "#56585D",
    textAlign: "center",
    fontFamily: "Gotham",
    fontSize: "36px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "32px",
    letterSpacing: "0.15px",
    marginTop: "10px",
  },
  fontNumber_choose: {
    color: "#56585D",
    textAlign: "center",
    fontFamily: "Gotham",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
  },
  fontNumber: {
    color: "#919499",
    textAlign: "center",
    fontFamily: "Gotham",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
  },
  textMode: {
    color: "#56585D",
    // textAlign: "center",
    fontFamily: "Gotham",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "21px" /* 175% */,
  },
  textModeFan: {
    color: "#56585D",
    // textAlign: "center",
    marginTop: '10px',
    fontFamily: "Gotham",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "21px",
  }
};
