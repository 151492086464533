import React, { useEffect } from "react";
import {
  Layout,
} from "antd";
import { useNavigate, Outlet, useLocation } from 'react-router-dom'
const { Header, Content } = Layout

export default function LayoutMain (props) {
  useEffect(() => {
    
  }, []);

  return (
    <div style={{ background: "#fff", height: "100vh"}}>
      {/* <Layout>
        <Content style={{ background: "#fff", height: "100vh", width: "0%" }}> */}
          <Outlet />
        {/* </Content>
      </Layout> */}
    </div>
  );
}
