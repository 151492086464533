import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Switch, Space, Slider, Spin } from "antd";
import ControllerPicture from "../picture/controller";

export default function AirCoolthing(props) {
  const [data, setData] = useState({
    device_fanspeed: "hight",
    device_id: "ac",
    device_mode: "cool",
    device_model: "daikin-coolthing",
    device_name: "air 1",
    device_status: "off",
    device_sweep: "stop",
    device_temp: "20",
    device_type: "ac",
    id: 0,
    mac: "84F3EBA16081",
    select_scene: "false",
  });

  useEffect(() => {
    if (props.data) {
      // console.log(props.data);

      setData(props.data);
    }
    return () => {
      // setData({})
    };
  }, [props.data]);

  useEffect(() => {
    props.getData(data);
    return () => {};
  }, [data]);

  const modes = [
    {
      key: 1,
      name: "Fan",
      status: "off",
    },
    {
      key: 2,
      name: "Cool",
      status: "off",
    },
    {
      key: 3,
      name: "Dry",
      status: "off",
    },
  ];
  const marks = {
    0: (
      <>
        <div style={styles.textMode}>Auto</div>
      </>
    ),
    20: (
      <>
        <div style={styles.textMode}>Low</div>
      </>
    ),
    40: (
      <>
        <div style={styles.textMode}></div>
      </>
    ),
    60: (
      <>
        <div style={styles.textMode}>Mid</div>
      </>
    ),
    80: (
      <>
        <div style={styles.textMode}></div>
      </>
    ),
    100: (
      <>
        <div style={styles.textMode}>High</div>
      </>
    ),
  };

  const fanspeed = (value) => {
    if (value === "auto") {
      return 0;
    } else if (value === "low") {
      return 20;
    } else if (value === "low_medium") {
      return 40;
    } else if (value === "medium") {
      return 60;
    } else if (value === "medium_high") {
      return 80;
    } else if (value === "high") {
      return 100;
    } else {
      return 0;
    }
  };

  const controllerTemp = (value) => {
    console.log("value", value);

    const number = parseInt(data["device_temp"]) + value;
    console.log(number);

    if (number >= 18 && number <= 32) {
      setData({
        ...data,
        device_temp: String(parseInt(data.device_temp) + value),
      });
    }
  };

  const controllerSpeed = (value) => {
    let speed = "auto";
    if (value === 0) {
      speed = "auto";
    } else if (value === 20) {
      speed = "low";
    } else if (value === 40) {
      speed = "low_medium";
    } else if (value === 60) {
      speed = "medium";
    } else if (value === 80) {
      speed = "medium_high";
    } else if (value === 100) {
      speed = "high";
    } else {
      speed = "low";
    }

    setData({ ...data, device_fanspeed: speed });
  };

  return (
    <div>
      {data.select_scene === "false" ? (
        <div style={styles.cardOff}>
          <Row gutter={[12, 12]}>
            <Col span={5} style={{ width: "250px" }}>
              <div
                onClick={() => {
                  setData({
                    ...data,
                    select_scene: "true",
                    device_status: "on",
                  });
                }}
              >
                {ControllerPicture.air()}
              </div>
            </Col>
            <Col span={19}>
              <Row>
                <Col span={17}>
                  <div
                    style={styles.fontHeadOff}
                    onClick={() => {
                      setData({
                        ...data,
                        select_scene: "true",
                        device_status: "on",
                      });
                    }}
                  >
                    Air Conditioner
                  </div>
                </Col>
                <Col span={7} style={{ textAlign: "end", paddingTop: "3px" }}>
                  <Space direction="vertical" style={{ marginLeft: "23%" }}>
                    <Switch
                      disabled
                      checked={data["device_status"] === "on" ? true : false}
                      checkedChildren="ON"
                      unCheckedChildren="OFF"
                    />
                  </Space>
                </Col>
              </Row>
              <div style={{ marginTop: "2%" }}>
                <Row>
                  <Col span={8} style={{ width: "250px", textAlign: "center" }}>
                    {ControllerPicture.minusD()}
                  </Col>
                  <Col span={8} style={styles.fontNumber}>
                    {data["device_temp"]}°
                  </Col>
                  <Col span={8} style={{ width: "250px", textAlign: "center" }}>
                    {ControllerPicture.plusD()}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <div>
            <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
              {modes.map((e, i) => {
                return (
                  <Col span={8} key={i + "_mode"}>
                    <div
                      style={
                        data["device_mode"] === e.name.toLowerCase()
                          ? styles.cardModeActionOff
                          : styles.cardModeOff
                      }
                    >
                      <div>
                        {e.name === "Fan" ? (
                          <>{ControllerPicture.wind()}</>
                        ) : null}
                        {e.name === "Cool" ? (
                          <>{ControllerPicture.cool()}</>
                        ) : null}
                        {e.name === "Dry" ? (
                          <>{ControllerPicture.dry()}</>
                        ) : null}
                      </div>
                      <div style={styles.fontInCardOff}>{e.name}</div>
                      <div style={{ marginTop: "10px" }}>
                        <Switch
                          checked={false}
                          checkedChildren="ON"
                          unCheckedChildren="OFF"
                        />
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
          <div style={styles.cardInervOff}>
            <Row>
              <Col span={7}>
                <Row>
                  <Col span={12} style={{ width: "200px" }}>
                    {ControllerPicture.wind()}
                  </Col>
                  <Col span={12}>
                    <div style={styles.fontInpageOff}>Fan</div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Slider
                  disabled
                  value={fanspeed(data["device_fanspeed"])}
                  marks={marks}
                  step={25}
                  tooltip={{
                    formatter: null,
                  }}
                />
              </Col>
            </Row>
          </div>
          <div style={styles.cardInervOff}>
            <Row>
              <Col span={5}>
                <div style={styles.fontInpageoff}>Swing</div>
              </Col>
              <Col span={19}>
                <Row>
                  {/* {JSON.stringify(data)} */}
                  <Col span={12}>
                    <div style={styles.textInbtn}>
                      <div
                        style={
                          data["device_sweep"] === "stop"
                            ? styles.btnSwingChooseoff
                            : styles.btnSwingoff
                        }
                      >
                        <Row gutter={[8, 8]}>
                          <Col>Stop</Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div style={styles.textInbtn}>
                      <div
                        style={
                          data["device_sweep"] === "swing"
                            ? styles.btnSwingChooseoff
                            : styles.btnSwingoff
                        }
                      >
                        <Row gutter={[8, 8]}>
                          <Col>Swing</Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      ) : null}
      {data.select_scene === "true" ? (
        <div style={styles.cardAction}>
          <Row gutter={[12, 12]}>
            <Col span={5} style={{ width: "250px" }}>
              <div
                onClick={() => {
                  setData({
                    ...data,
                    select_scene: "false",
                    device_status: "off",
                  });
                }}
              >
                {ControllerPicture.air()}
              </div>
            </Col>
            <Col span={19}>
              <Row>
                <Col span={17}>
                  <div
                    style={styles.fontHead}
                    onClick={() => {
                      setData({
                        ...data,
                        select_scene: "false",
                        device_status: "off",
                      });
                    }}
                  >
                    Air Conditioner
                  </div>
                </Col>
                <Col span={7} style={{ textAlign: "end", paddingTop: "3px" }}>
                  <Space direction="vertical" style={{ marginLeft: "23%" }}>
                    <Switch
                      onChange={(e) => {
                        let status = "off";
                        if (e) {
                          status = "on";
                        }
                        setData({ ...data, device_status: status });
                      }}
                      checked={data["device_status"] === "on" ? true : false}
                      checkedChildren="ON"
                      unCheckedChildren="OFF"
                    />
                  </Space>
                </Col>
              </Row>
              <div style={{ marginTop: "2%" }}>
                <Row>
                  <Col span={8} style={{ width: "250px", textAlign: "center" }}>
                    {data["device_mode"] !== "fan" &&
                    data["device_status"] === "on" ? (
                      <div
                        onClick={() => {
                          controllerTemp(-1);
                        }}
                      >
                        {ControllerPicture.minus()}
                      </div>
                    ) : (
                      <div>{ControllerPicture.minusD()}</div>
                    )}
                  </Col>
                  <Col span={8} style={styles.fontNumber}>
                    {data["device_temp"]}°
                  </Col>
                  <Col span={8} style={{ width: "250px", textAlign: "center" }}>
                    {data["device_mode"] !== "fan" &&
                    data["device_status"] === "on" ? (
                      <div
                        onClick={() => {
                          controllerTemp(1);
                        }}
                      >
                        {ControllerPicture.plus()}
                      </div>
                    ) : (
                      <div>{ControllerPicture.plusD()}</div>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <div>
            <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
              {modes.map((e, i) => {
                return (
                  <Col span={8} key={i + "_mode"}>
                    <div style={styles.cardMode}>
                      <div>
                        {e.name === "Fan" ? (
                          <>{ControllerPicture.wind()}</>
                        ) : null}
                        {e.name === "Cool" ? (
                          <>{ControllerPicture.cool()}</>
                        ) : null}
                        {e.name === "Dry" ? (
                          <>{ControllerPicture.dry()}</>
                        ) : null}
                      </div>
                      <div style={styles.fontInCard}>{e.name}</div>
                      <div style={{ marginTop: "10px" }}>
                        <Switch
                          disabled={
                            data["device_status"] === "off" ? true : false
                          }
                          onChange={() => {
                            setData({
                              ...data,
                              device_mode: e.name.toLowerCase(),
                            });
                          }}
                          checked={
                            data["device_mode"].toLowerCase() ===
                            e.name.toLowerCase()
                              ? true
                              : false
                          }
                          checkedChildren="ON"
                          unCheckedChildren="OFF"
                        />
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
          <div style={styles.cardInerv}>
            <Row>
              <Col span={7}>
                <Row>
                  <Col span={12} style={{ width: "200px" }}>
                    {ControllerPicture.wind()}
                  </Col>
                  <Col span={12}>
                    <div style={styles.fontInpage}>Fan</div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Slider
                  marks={marks}
                  disabled={data["device_status"] === "off" ? true : false}
                  value={fanspeed(data["device_fanspeed"])}
                  step={50}
                  onChange={controllerSpeed}
                  tooltip={{
                    formatter: null,
                  }}
                />
              </Col>
            </Row>
          </div>
          <div style={styles.cardInerv}>
            <Row>
              <Col span={5}>
                <div style={styles.fontInpageoff}>Swing</div>
              </Col>
              <Col span={19}>
                <Row>
                  <Col span={12}>
                    <div style={styles.textInbtn}>
                      <div
                        style={
                          data["device_sweep"] === "stop"
                            ? styles.btnSwingChoose
                            : styles.btnSwing
                        }
                        onClick={() => {
                          setData({ ...data, device_sweep: "stop" });
                        }}
                      >
                        <Row gutter={[8, 8]}>
                          <Col>Stop</Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div style={styles.textInbtn}>
                      <div
                        style={
                          data["device_sweep"] === "swing"
                            ? styles.btnSwingChoose
                            : styles.btnSwing
                        }
                        onClick={() => {
                          setData({ ...data, device_sweep: "swing" });
                        }}
                      >
                        <Row gutter={[8, 8]}>
                          <Col>Swing</Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      ) : null}
    </div>
  );
}

const styles = {
  head: {
    backgroundColor: "#F0F9FB",
    height: "108px",
  },
  card: {
    display: "flex",
    padding: "16px",
    flexDirection: "column",
    // alignItems: "flex-start",
    gap: "12px",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
    background: "#FBFBFB",
  },
  cardAction: {
    display: "flex",
    padding: "16px",
    flexDirection: "column",
    // alignItems: "flex-start",
    gap: "12px",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
    background: "#EBF4FA",
  },
  cardInerv: {
    // width: "90%",
    // width: "100%",
    // height: "62px",
    flexShrink: "0",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
    background: "#FBFBFB",
    padding: "15px 15px 0 15px",
  },
  fontInpage: {
    color: "#3EA8B8",
    // textAlign: "center",
    paddingTop: "2px",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px" /* 150% */,
  },
  fontHead: {
    fontFamily: "Gotham",
    color: "#3EA8B8",
    textAlign: "center",
    paddingTop: "2px",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px" /* 150% */,
    // marginLeft: "25px",
  },
  fontInCard: {
    color: "#3EA8B8",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px" /* 150% */,
    // marginLeft: "10%",
  },
  fontNumber: {
    color: "#56585D",
    fontFamily: "Gotham",
    fontSize: "36px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "32px",
    letterSpacing: "0.15px",
    textAlign: "center",
    marginTop: "10px",
  },
  textMode: {
    color: "#56585D",
    marginTop: "10px",
    textAlign: "center",
    fontFamily: "Gotham",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "21px" /* 175% */,
  },
  cardMode: {
    height: "105px",
    // width: "93px",
    textAlign: "center",
    flexShrink: "0",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
    background: "#FBFBFB",
    paddingTop: "15%",
  },
  cardModeAction: {
    height: "105px",
    // width: "93px",
    textAlign: "center",
    flexShrink: "0",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
    background: "rgba(235, 244, 250, 1)",
    paddingTop: "15%",
  },

  fontHeadOff: {
    fontFamily: "Gotham",
    color: "#56585D",
    textAlign: "center",
    paddingTop: "2px",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
  },
  cardOff: {
    display: "flex",
    padding: "16px",
    flexDirection: "column",
    gap: "12px",
    borderRadius: "16px",
    border: "1px solid #E3E3E3",
    background: "#FBFBFB",
  },
  fontInCardOff: {
    color: "#56585D",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
  },
  fontInpageOff: {
    color: "#56585D",
    paddingTop: "2px",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
  },
  cardModeOff: {
    height: "105px",
    textAlign: "center",
    flexShrink: "0",
    borderRadius: "16px",
    border: "1px solid #E3E3E3",
    background: "#FBFBFB",
    paddingTop: "15%",
  },
  cardModeActionOff: {
    height: "105px",
    textAlign: "center",
    flexShrink: "0",
    borderRadius: "16px",
    border: "1px solid #E3E3E3",
    background: "#E3E3E3",
    paddingTop: "15%",
  },
  cardInervOff: {
    flexShrink: "0",
    borderRadius: "16px",
    border: "1px solid #E3E3E3",
    background: "#FBFBFB",
    padding: "15px 15px 0 15px",
  },
  btnSwing: {
    display: "flex",
    width: "40px",
    height: "40px",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
    borderRadius: "14px",
    border: "1px solid #E3E3E3",
    background: "#FBFBFB",
    boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.10)",
  },
  btnSwingChoose: {
    display: "flex",
    width: "40px",
    height: "40px",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
    borderRadius: "14px",
    border: "1px solid #B5EAEF",
    background: "rgba(235, 244, 250, 1)",
    boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.10)",
  },
  textInbtn: {
    height: "100px",
    alignItems: "center",
    display: "flex",
  },
  btnSwingoff: {
    display: "flex",
    width: "40px",
    height: "40px",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
    borderRadius: "14px",
    border: "1px solid #E3E3E3",
    background: "#FBFBFB",
    boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.10)",
  },
  btnSwingChooseoff: {
    display: "flex",
    width: "40px",
    height: "40px",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
    borderRadius: "14px",
    border: "1px solid #E3E3E3",
    background: "#E3E3E3",
    boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.10)",
  },
};
